import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  APIURL = environment.APIURL;

  constructor(public http: HttpClient, public router: Router) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    // Check the internet Connection
    if (!navigator.onLine) {
      
    } else {
      if (error.error instanceof ErrorEvent) {
        // Client-side errors
        errorMessage = `Error: ${error.error.message}`;
        // 400, 401, 403, 404, 405, 409
        if (error.status == 400) {
          
        } else if (error.status == 401) {
          
        } else if (error.status == 403) {
          
        } else if (error.status == 404) {
          
        } else if (error.status == 405) {
          
        } else if (error.status == 409) {
          
        }
        // Generic way to display the messag and status in console window
        window.alert(errorMessage);
        return throwError(errorMessage);
      } else {
        // Server-side errors
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        // 500, 503
        // if (error.status == 500) {
        //   
        //   // Generic way to display the messag and status in console window
        //   window.alert(errorMessage);
        //   return throwError(errorMessage);
        // } else if (error.status == 503) {
        //   
        //   // Generic way to display the messag and status in console window
        //   window.alert(errorMessage);
        //   return throwError(errorMessage);
        // }
      }
    }
  }

  saveUserProfileData(data): Observable<any> {
    var saveUserProfileDataURL = this.APIURL + "customer_update_profile.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = data;
    // console.log("Service called, URL : " + saveUserProfileDataURL);
    return this.http
      .post(saveUserProfileDataURL, body, {
        headers,
        responseType: "text" as "json",
      })
  }

  changePassword(data): Observable<any> {
    var changePasswordDataURL = this.APIURL + "customer_change_password.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = data;
    // console.log("Service called, URL : " + changePasswordDataURL);
    return this.http
      .post(changePasswordDataURL, body, {
        headers,
        responseType: "text" as "json",
      })
  }
}
