import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PaymentPageService {
  APIURL = environment.APIURL;

  constructor(public http: HttpClient) {}
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  verifyPhonepeVPA(merchantId, vpa, SHAstr): Observable<any> {
    // let headers = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   "x-verify": SHAstr,
    // });
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("x-verify", SHAstr);
    var dhinsiShopListURL =
      "/api/v3/vpa/" + merchantId + "/" + vpa + "/validate";
    // console.log("Service called, URL : " + dhinsiShopListURL);
    return this.http
      .get(dhinsiShopListURL, { headers })
      .pipe(catchError(this.handleError));
  }

  phonepe(request, xverify): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("x-verify", xverify);
    // .set("x-redirect-url", "http://localhost:4200/homePage/paymentOptions");
    // console.log("Service called, URL : " + dhinsiShopListURL);
    var phonepeURL = "/api/v3/debit";
    var body = { "request": request };
    return this.http
      .post(phonepeURL, body, { headers, responseType: "text" as "json" })
      .pipe(catchError(this.handleError));
  }
}
