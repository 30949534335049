import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ShopitemsComponent } from "./shopitems/shopitems.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { HomePageComponent } from "./home-page.component";
import { PaymentOptionDetailsComponent } from "./payment-option-details/payment-option-details.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { FaqComponent } from "./faq/faq.component";
import { RoutingGuard } from "../shared/routing.gaurd";
import { AuthGaurd } from '../shared/auth.gaurd';
import { ShopDetailsFormComponent } from './shop-details-form/shop-details-form.component';
import { AboutComponent } from "./about/about.component";
import { UploadImageComponent } from "./upload-image/upload-image.component";

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      {
        path: "",
        component: ShopDetailsFormComponent,
        canActivate: [RoutingGuard],
      },
      {
        path: "homePage/shopDetailForm",
        component: ShopDetailsFormComponent,
        canActivate: [RoutingGuard],
      },
      {
        path: "homePage/shopItemList",
        component: ShopitemsComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile",
        component: UserProfileComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/feedback",
        component: FeedbackComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/faq",
        component: FaqComponent,
        canActivate: [RoutingGuard],
      },
      {
        path: "homePage/about",
        component: AboutComponent,
        canActivate: [RoutingGuard],
      },
      {
        path: "homePage/orderHistory",
        component: OrderHistoryComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/checkout",
        component: CheckoutComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/paymentOptions",
        component: PaymentOptionDetailsComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/uploadImage",
        component: UploadImageComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
