<mat-toolbar class="bg-light">
    <mat-toolbar-row class="pl-0 pr-0">
        <button [disableRipple]="true" routerLink="/homePage/shopDetailForm"
            class="btn btn-outline-light bg-light p-0 backBtnMargin" mat-button>
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span style="flex: 1 1 auto;"></span>
        <div>
            <button color="accent" class="btn btn-outline-light mr-2" (click)="fileInput.click()" mat-mini-fab>
                <mat-icon matBadgePosition="after">cloud_upload</mat-icon>
            </button>
            <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file">
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<body class="bg-light left-area-bg-image">
    <div *ngIf="imageChangedEvent == ''" class="row justify-content-center text-center">
        <div class="d-inline">
            <!-- <img class="jump" src="assets/images/arrow-up.png" height="100" width="60"> -->
            <p class="text-font-style font-weight-bold">Upload the Photo</p>
            <p class="text-font-style font-weight-bold">ಫೋಟೋ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ</p>
        </div>
    </div>
    <div class="row justify-content-center align-items-center">
        <div class="d-inline">
            <div class="text-center">
                <button mat-mini-fab color="warn" class="btn btn-outline-danger matbuttonaddremove"
                    *ngIf="imageChangedEvent != ''" (click)="imageChangedEvent = ''">
                    <mat-icon class="maticon">clear</mat-icon>
                </button>
            </div>
            <div class="d-flex" style="margin-top: 5%;">
                <div class="col-12">
                    <image-cropper *ngIf="imageChangedEvent != ''" [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToHeight]="165"
                        [cropperMinWidth]="200" [cropperMinHeight]="200" format="png"
                        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                </div>
                <!-- <div *ngIf="imageChangedEvent != ''" class="pl-0 col-6">
                        <img [src]="croppedImage" style="height: 165px;" />
                    </div> -->
            </div>
        </div>

        <button mat-raised-button *ngIf="imageChangedEvent != ''" color="primary" class="btn btn-outline-primary mt-5">
            Place Order<mat-icon matBadgePosition="after">keyboard_arrow_right</mat-icon>
        </button>
    </div>
</body>