import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Event as NavigationEvent } from "@angular/router";
import { NavigationStart } from "@angular/router";

@Injectable()
export class RoutingGuard implements CanActivate {
  currentUrl: any;
  constructor(public router: Router) {
    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: NavigationStart) => {
        // console.log(this.router.url);
        // console.log(event.url);
        this.currentUrl = event.url;
        if (event.restoredState) {
          // console.log("restoring navigation id:", event.restoredState.navigationId);
        }
        console.groupEnd();
      });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // If the previous URL was blank, then the user is directly accessing this page
    // this.router.url;
    // this.currentUrl;
    // if (this.router.url === "/") {
    // this.router.navigate([""]); // Navigate away to some other page
    // return false;
    // }
    return true;
  }
}
