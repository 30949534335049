import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginSignupService } from "src/app/service/login-signup-service";
import { HomePageService } from "src/app/service/home-page.service";
import { MessagingService } from "src/app/service/messaging.service";

@Component({
  selector: "app-shop-details-form",
  templateUrl: "./shop-details-form.component.html",
  styleUrls: ["./shop-details-form.component.css"],
})
export class ShopDetailsFormComponent implements OnInit {
  list: any;
  areaList: any;
  shopList: any;
  isLogout: boolean;
  isShopListLoaded: boolean;
  destinationList: any;
  selectedShopID: string;
  @ViewChild("logoutDialog", { static: true })
  logoutDialog: TemplateRef<any>;
  @ViewChild("generalDialog", { static: true })
  generalDialog: TemplateRef<any>;
  groceryDetails: FormGroup = new FormGroup({
    shopArea: new FormControl("", [Validators.required]),
    // groceryStoreName: new FormControl({ value: "", disabled: true }, [
    //   Validators.required,
    // ]),
    // destination: new FormControl("", [Validators.required]),
    // detailAddress: new FormControl("", [Validators.required]),
  });
  dialogData: any = {
    title: "",
    content: "",
    note: "",
    imagePath: "",
    yes: "",
    no: "",
  };

  constructor(
    public service: HomePageService,
    public loginsignupservice: LoginSignupService,
    public router: Router,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public messagingService: MessagingService
  ) {
    this.dialog.closeAll();
  }

  ngOnInit() {
    this.list = [];
    this.areaList = [];
    this.shopList = [];
    this.destinationList = [];
    this.selectedShopID = "";
    this.isLogout = false;
    this.isShopListLoaded = false;

    // console.log(this.router.getCurrentNavigation().extras.state);

    // localStorage.removeItem("source");
    // localStorage.removeItem("shopDetails");
    // localStorage.removeItem("cartItemList");
    // localStorage.removeItem("itemCategories");
    // this.service.storeShopDetails({ shopid: null });

    this.service.getShopList().subscribe((result: any) => {
      this.isShopListLoaded = true;
      this.list = result;
      this.areaList = result
        .map((item) => item.shoparea)
        .filter((value, index, self) => self.indexOf(value) === index);
      this.destinationList = this.areaList;
    });
  }

  onAreaChange() {
    this.shopList = [];
    let selectedArea = this.groceryDetails.controls["shopArea"].value;
    this.list.filter((x) => {
      if (x.shoparea.toUpperCase() === selectedArea.toUpperCase()) {
        // this.selectedShopID = x.shopid;
        this.shopList.push({
          shopname: x.shopname,
          shopname_kannada: x.shopname_kannada,
          shopid: x.shopid,
          shopImage: x.shopimage,
          shopkeeperImage: x.shopkeeperimage,
          online_status: x.online_status,
        });
      }
    });
    // this.groceryDetails.controls["groceryStoreName"].enable();
  }

  onShopChange(event) {
    // console.log(event);
    this.selectedShopID = event;
  }
  // resetAddress() {
  //   this.groceryDetails.controls["address"].reset();
  //   // this.groceryDetails.controls['address'].value = '';
  // }

  requestShopDetails(value) {
    if (value.online_status == "Online") {
      if (JSON.parse(localStorage.getItem("cartItemList")) != null) {
        if (
          JSON.parse(localStorage.getItem("shopDetails"))["shopid"] !=
          value.shopid
        ) {
          let shopName = JSON.parse(localStorage.getItem("shopDetails"))[
            "shopname"
          ];
          let shopNameKannada = JSON.parse(localStorage.getItem("shopDetails"))[
            "shopname_kannada"
          ];
          this.dialogData = {
            title: "Clear cart items",
            content:
              "You have previously added items from " +
              shopName +
              " (" +
              shopNameKannada +
              "). \n\n Do you want to clear the cart and proceed?",
            note: "You can not order from two different shops at a time.",
            imagePath: "",
            yes: "Yes",
            no: "No",
          };
          const dialogRef = this.dialog.open(this.generalDialog, {
            disableClose: true,
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined) {
              if (result == "Yes") {

                /** Show a modal to give option for customer */
                this.dialogData = {
                  title: "Select any option",
                  content:
                    "Choose any one option to proceed further. \n(ನೀವು ಅಂಗಡಿಗೆ ಚೀಟಿಯ ಪಟ್ಟಿಯನ್ನು ಕಳಿಸಬಹುದು  ಅಥವಾ ನೇರ ಅಂಗಡಿಯಲ್ಲಿ ಲಭ್ಯವಿರುವ ಸಾಮಾಗ್ರಿಗಳನ್ನು ನೋಡಿ ಆರ್ಡರ್ ಮಾಡಬಹುದು.)",
                  note: "",
                  imagePath: "",
                  yes: "Select items from Shop",
                  no: "Send Photo to the shop",
                };
                const dialogRef = this.dialog.open(this.generalDialog, {
                  disableClose: false,
                  autoFocus: false,
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result != undefined) {
                    if (result == "Send Photo to the shop") {
                      this.service.storeGroceryDetails(
                        this.groceryDetails.controls["shopArea"].value
                      );
                      this.service.storeShopDetails({ shopDetails: value });
                      this.router.navigateByUrl("/homePage/uploadImage");
                    } else {
                      localStorage.removeItem("source");
                      localStorage.removeItem("shopDetails");
                      localStorage.removeItem("cartItemList");
                      localStorage.removeItem("itemCategories");
                      this.service.storeGroceryDetails(
                        this.groceryDetails.controls["shopArea"].value
                      );
                      this.service.storeShopDetails({ shopDetails: value });
                      this.router.navigateByUrl("/homePage/shopItemList");
                    }
                  }
                });
              }
            }
          });
        } else {
          /** Show a modal to give option for customer */
          this.dialogData = {
            title: "Select any option",
            content:
              "Choose any one option to proceed further. \n(ನೀವು ಅಂಗಡಿಗೆ ಚೀಟಿಯ ಪಟ್ಟಿಯನ್ನು ಕಳಿಸಬಹುದು  ಅಥವಾ ನೇರ ಅಂಗಡಿಯಲ್ಲಿ ಲಭ್ಯವಿರುವ ಸಾಮಾಗ್ರಿಗಳನ್ನು ನೋಡಿ ಆರ್ಡರ್ ಮಾಡಬಹುದು.)",
            note: "",
            imagePath: "",
            yes: "Select items from Shop",
            no: "Send Photo to the shop",
          };
          const dialogRef = this.dialog.open(this.generalDialog, {
            disableClose: false,
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined) {
              if (result == "Send Photo to the shop") {
                this.service.storeGroceryDetails(
                  this.groceryDetails.controls["shopArea"].value
                );
                this.service.storeShopDetails({ shopDetails: value });
                this.router.navigateByUrl("/homePage/uploadImage");
              } else {
                this.service.storeGroceryDetails(
                  this.groceryDetails.controls["shopArea"].value
                );
                this.service.storeShopDetails({ shopDetails: value });
                this.router.navigateByUrl("/homePage/shopItemList");
              }
            }
          });
        }
      } else {
        /** Show a modal to give option for customer */
        this.dialogData = {
          title: "Select any option",
          content:
            "Choose any one option to proceed further. \n(ನೀವು ಅಂಗಡಿಗೆ ಚೀಟಿಯ ಪಟ್ಟಿಯನ್ನು ಕಳಿಸಬಹುದು  ಅಥವಾ ನೇರ ಅಂಗಡಿಯಲ್ಲಿ ಲಭ್ಯವಿರುವ ಸಾಮಾಗ್ರಿಗಳನ್ನು ನೋಡಿ ಆರ್ಡರ್ ಮಾಡಬಹುದು.)",
          note: "",
          imagePath: "",
          yes: "Select items from Shop",
          no: "Send Photo to the shop",
        };
        const dialogRef = this.dialog.open(this.generalDialog, {
          disableClose: false,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result != undefined) {
            if (result == "Send Photo to the shop") {
              this.service.storeGroceryDetails(
                this.groceryDetails.controls["shopArea"].value
              );
              this.service.storeShopDetails({ shopDetails: value });
              this.router.navigateByUrl("/homePage/uploadImage");
            } else {
              this.service.storeGroceryDetails(
                this.groceryDetails.controls["shopArea"].value
              );
              this.service.storeShopDetails({ shopDetails: value });
              this.router.navigateByUrl("/homePage/shopItemList");
            }
          }
        });
      }
    }
  }

  logout() {
    const dialogRef = this.dialog.open(this.logoutDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.isLogout = true;
          let data = JSON.parse(localStorage.getItem("Customer_sessionData"));
          this.messagingService.deviceToken.subscribe((token) => {
            data.deviceToken =
              token === null ? data.username + "_noDeviceIdGenerated" : token;
          });
          this.loginsignupservice.logout(data).subscribe((result) => {
            if (result == "Logged out successfully") {
              this.isLogout = false;
              // localStorage.clear();
              localStorage.removeItem("shopId");
              localStorage.removeItem("source");
              localStorage.removeItem("Customer_sessionData");
              localStorage.removeItem("HomePageErrorCode");
              localStorage.removeItem("LoginPageErrorCode");
              localStorage.removeItem("cartItemList");
              this.router.navigate(["/"]);
            } else {
              this.isLogout = false;
              this._snackBar.open(
                "Logout failed! Please try after sometime.",
                null,
                {
                  duration: 2000,
                  horizontalPosition: "center",
                  verticalPosition: "bottom",
                }
              );
            }
          });
        }
      }
    });
  }
}
