import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FaqComponent implements OnInit {
  panelOpenState = false;
  questionsAndAnswers = [
    {
      question: "Why Dhinasiangadi?",
      answer: "To make ease of transaction between customer and shopkeeper through digital platform to fulfill their demands",
    },
    {
      question: "Will all shops be included in Dhinasiangadi?",
      answer: "Yes, most of the shops have been included and will be added on customer demand. <a href='https://mail.google.com/mail/?view=cm&fs=1&to=dhinasinangadi@gmail.com&su=Requesting for adding grocery shop.&body=' target='_blank'>Suggest if any</a>",
    },
    {
      question:"How can I place an order?",
      answer:"<p>1. Add the items into the cart</p><hr><p>2. Click on cart icon to verify the list and click proceed</p><hr><p>3. Fill the delivey details and choose the payment option to make the order.</p>"
    },
    {
      question:"How do I know my order is confirmed?",
      answer:"Once the shopkeeper accept/cancel your order, you will be notified through a notification on the status of your order. Also, you can also check the status of your order in order history"
    },
    {
      question: "What are the payment options accepted?",
      answer: "We accept Cash on delivery. Once you get the ordered items to your door step. You can transfer money through any UPI applications if you wish to avoid giving hard cash.",
    },
    {
      question:"Can I cancel my order?",
      answer:"Once the order is placed, it cannot be cancelled"
    },
    {
      question:"Can I change the delivery details after ordering?",
      answer:"Once the order placed, delivery details can not be modified"
    },
    {
      question: "Will customer get order delivered when informed neighbours to receive?",
      answer: "We verify the order with customer and expect customer to be available while delivery",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
