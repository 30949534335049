<!-- <mat-card class="h-100 style-6 overflow-scroll mb-5 text-center"> -->
<mat-toolbar class="bg-white sticky-top shadow-sm">
    <mat-toolbar-row>
        <span class="text-font-style d-block d-sm-block d-md-none d-lg-none d-xl-none"
            style="font-size: xx-large;">About Us</span>
        <span class="text-font-style display-4 d-none d-md-block d-lg-block d-xl-block">About Us</span>
        <span style="flex: 1 1 auto;"></span>
        <button *ngIf="!isLoginPage" class="btn btn-outline-light" mat-icon-button routerLink="/homePage/userProfile/">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
<mat-card class="container text-justify mt-3 shadow mb-5">
    <p class="text-font-style text-center" style="font-size: xx-large;">Dhinasi Angadi</p>
    <mat-divider></mat-divider>
    <p class="text-font-style mt-5"><strong>DHINASI ANGADI</strong> idea was decided as we all wanted to make our life
        easier at this time of pandemic. It is mainly for the
        people of SHIVAMOGGA. As safety matters, choice also matters. This platform brings together all the shops in
        our
        city at the same place which will provide all the services required by the customer. Home delivery safe
        delivery
        is our main motto. Get all groceries from your regular shop to your door step with all hygiene maintained.
    </p>
    <br>
    <br>
    <p class="text-font-style">Pure transparency between the customers and the shop keeper is maintained. A small
        initiative for this situation
        can help so many lives is what we believe. Main concern is to preserve the value of our own shop keepers. In
        the
        midst of all the online fantasies local stores shouldn’t be faded.</p>
    <br>
    <p class="text-font-style">And we always want to hear from people of their choice which was our inspiration to
        start this venture. Each of
        the voices were up to this and tried to reach few of them.</p>
    <br>
    <p class="text-font-style">Customer satisfaction with all safety, hygiene and with the same old fragrance of
        their choice is the main goal.
        All the feedbacks, advices and suggestions are valued the most.
        A small step to help people to be safe at their place.</p>

    <p class="text-font-style text-center mt-5" style="font-size: xx-large;">ದಿನಸಿ ಅಂಗಡಿ</p>
    <mat-divider></mat-divider>
    <p class="text-font-style mt-5">
        COVID-19 ಹೆಚ್ಚುತ್ತಿರುವ ಈ ಸಮಯದಲ್ಲಿ ನಾವೆಲ್ಲರೂ ನಮ್ಮ ಜೀವನವನ್ನು ಸುಲಭಗೊಳಿಸಲು ಬಯಸಿದ್ದರಿಂದ. ದಿನಸಿ ಅಂಗಡಿಯ ಕಲ್ಪನೆಯು
        ಹುಟ್ಟಿಕೊಂಡಿತು.

        ಇದು ಪ್ರಮುಖವಾಗಿ ಶಿವಮೊಗ್ಗದ ಜನರಿಗಾಗಿ ಹಾಗೂ ಜನರಿಗೋಸ್ಕರ ಮಾಡಿರುವ ಆ್ಯಪ್ ಆಗಿದೆ.

        ಆರೋಗ್ಯದ ಸುರಕ್ಷತೆಯ ವಿಷಯವಾಗಿ, ನಮ್ಮ ಜೀವನದ ಆಯ್ಕೆಯೂ ಅಷ್ಟೇ ಮುಖ್ಯವಾಗಿದೆ.

        ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ನಮ್ಮ ನಗರದ ಎಲ್ಲಾ ನೋಂದಾಯಿತ ದಿನಸಿ ಅಂಗಡಿಗಳನ್ನು ಒಂದೇ ಸ್ಥಳದಲ್ಲಿ ಒಟ್ಟುಗೂಡಿಸುತ್ತದೆ ಹಾಗೂ ಗ್ರಾಹಕರಿಗೆ
        ಸುರಕ್ಷತೆಯ ರೀತಿಯಿಂದ ಅಗತ್ಯವಿರುವ ಎಲ್ಲಾ ಸೇವೆಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ.


        "ಸ್ವಚ್ಚ ಹಾಗೂ ಸುರಕ್ಷಿತ ವಿತರಣೆ" ಯೇ ನಮ್ಮ ಮುಖ್ಯ ಕಲ್ಪನೆ ಹಾಗೂ ಧ್ಯೇಯವಾಗಿದೆ.

        ನಮ್ಮ ವಿತರಣೆಯ ಸೇವೆಯು, ಎಲ್ಲಾ ರೀತಿಯ ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಂಡು, ದಿನಸಿ ಅಂಗಡಿಯಿಂದ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ಎಲ್ಲಾ ದಿನಸಿ
        ವಸ್ತುಗಳನ್ನು ಪಡೆಯುವಲ್ಲಿ ಹೆಚ್ಚು ಸಹಾಯಕವಾಗಿದೆ.
    </p>
    <br><br>
    <p class="text-font-style">
        ನಮ್ಮ ಆ್ಯಪ್, ಗ್ರಾಹಕರು ಮತ್ತು ಅಂಗಡಿ ಮಾಲೀಕರ ನಡುವೆ ಶುದ್ಧ ಪಾರದರ್ಶಕತೆಯನ್ನು ಕಾಪಾಡುತ್ತದೆ.

        ಈ ಪರಿಸ್ಥಿತಿಯಲ್ಲಿ, ಜನರಿಗೆ ಸಹಾಯವಾಗುವಂತಹ ಒಂದು ಸಣ್ಣ ಹೆಜ್ಜೆಯೂ ಅನೇಕ ಜೀವಗಳಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ ಎಂಬುದು ನಮ್ಮ ನಂಬಿಕೆಯಾಗಿದೆ.

        ಹಾಗೆಯೇ ಅಂಗಡಿ ಮಾಲೀಕರ ಮೌಲ್ಯವನ್ನು ಕಾಪಾಡುವುದೂ ನಮ್ಮ ಮುಖ್ಯ ಕಾಳಜಿಯಾಗಿದೆ.

        ಎಲ್ಲಾ ಆನ್‌ಲೈನ್ ಕಲ್ಪನೆಗಳ ಮಧ್ಯೆ, ಸ್ಥಳೀಯ ಮಳಿಗೆಗಳು ಮರೆಯಾಗಬಾರದು.
    </p>
    <br><br>
    <p class="text-font-style">
        ನಾವು ಯಾವಾಗಲೂ ಜನರ ಆಯ್ಕೆಯನ್ನು ಅವರ ಬಾಯಿನಿಂದಲೇ ಕೇಳಲು ಬಯಸುತ್ತೇವೆ. ಹಾಗಾಗಿ, ಈ ಉದ್ದೇಶವು ನಮಗೆ ನಮ್ಮ ಆ್ಯಪ್ ನ ಕಲ್ಪನೆಯನ್ನು
        ಪ್ರಾರಂಭಿಸಲು ಪ್ರೇರಣೆಯಾಗಿತ್ತು.

        ಪ್ರತಿಯೊಬ್ಬರ ಆಯ್ಕೆಯೂ ಹಾಗೂ ಸಲಹೆಯೂ ಇದಕ್ಕೆ ಅನುಗುಣವಾಗಿತ್ತು ಮತ್ತು ಅವುಗಳಲ್ಲಿ ಆದಷ್ಟನ್ನು ತಲುಪಲು ನಾವೂ ಸಹ ಪ್ರಯತ್ನಿಸಿದ್ದೇವೆ.
    </p>
    <br><br>
    <p class="text-font-style">
        ಸುರಕ್ಷತೆ, ನೈರ್ಮಲ್ಯ ಮತ್ತು ಗ್ರಾಹಕರ ತೃಪ್ತಿಯೇ ನಮ್ಮ ಮುಖ್ಯ ಗುರಿಯಾಗಿದ್ದು, ಎಲ್ಲಾ ರೀತಿಯ ಸಲಹೆಗಳು, ಸೂಚನೆಗಳು ಮತ್ತು
        ಪ್ರತಿಕ್ರಿಯೆಗಳು ನಮಗೆ ಬಹಳ ಮೌಲ್ಯವಾಗಿದೆ.

        ಈ ಆ್ಯಪ್ ನ ಮುಖಾಂತರ, ಜನರಿಗೆ ತಮ್ಮ ಸ್ಥಳದಲ್ಲಿ ಸುರಕ್ಷಿತವಾಗಿರಲು ಸಹಾಯ ಮಾಡುವ ಒಂದು ಸಣ್ಣ ಹೆಜ್ಜೆ ಇದಾಗಿದೆ.
    </p>
</mat-card>
<!-- </mat-card> -->