import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { HomePageService } from "src/app/service/home-page.service";
import { MatTableDataSource } from "@angular/material/table";
import { MessagingService } from "src/app/service/messaging.service";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.css"],
})
export class OrderHistoryComponent implements OnInit {
  todayDate: Date = new Date();
  panelOpenState: boolean;
  orderHistoryData: any;
  isOrderHistoryReceived: boolean;
  displayedColumns: string[] = ["item_name", "cost_price", "itemPrice"];
  itemList: any;
  status: any;
  selectedTab: number = 0;
  dialogData: any = {
    title: "",
    content: "",
    note: "",
  };
  @ViewChild("generalDialog", { static: true })
  generalDialog: TemplateRef<any>;

  constructor(
    public service: HomePageService,
    public messagingService: MessagingService,
    private dialog: MatDialog,
    private router: Router
  ) {
    // Check the changes of Orders
    this.messagingService.orderChangeValue.subscribe((response) => {
      if (response != null) {
        this.dialogData = {
          title: response,
          content: "Status: " + response,
          note: "",
        };
        let dialogRef = this.dialog.open(this.generalDialog, {
          disableClose: true,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe((result) => {
          // Note: If the user clicks outside the dialog or presses the escape key, there'll be no result
          if (result !== undefined) {
            if (result === "Yes") {
              this.getOrderHistory();
            } else {
              this.ngOnInit();
            }
          }
        });
      }
    });
  }

  ngOnInit() {
    // this.messagingService.changeOrderStatus(null);
    this.panelOpenState = false;
    this.isOrderHistoryReceived = true;
    this.orderHistoryData = [];
    let Customer_sessionData = JSON.parse(
      localStorage.getItem("Customer_sessionData")
    );
    if (Customer_sessionData === null) {
      // if (this.router.url === "/homePage/orderHistory") {
      //   this.router.navigate(["/"]);
      // } else {
      //   this.router.navigate(["/login"]);
      // }
      this.router.navigate(["/login"]);
    } else {
      this.getOrderHistory();
    }
  }
  getOrderHistory() {
    let customerID = JSON.parse(localStorage.getItem("Customer_sessionData"))
      .customerid;
    this.service.getOrderHistory(customerID).subscribe((result) => {
      if (result.length != 0) {
        let groupedResults = _.groupBy(result, (result) =>
          moment(new Date(result.timestamp), "DD/MM/YYYY").startOf("isoWeek")
        );

        this.orderHistoryData = result;
        this.isOrderHistoryReceived = false;

        this.itemList = this.orderHistoryData;
        this.itemList.forEach((element) => {
          let cartItems = [];
          if (element.delivertime == "Quick Delivery") {
            element.finalAmountToPay = parseInt(element.deliveryamount);
          } else {
            element.finalAmountToPay =
              parseInt(element.cartamount) + parseInt(element.deliveryamount);
          }
          cartItems = JSON.parse(element.cart_Items);
          cartItems.forEach((cart) => {
            let initialCost = parseInt(cart.itemPrice) / cart.initialQuantity;
            cart.initialCost = initialCost;
          });
          element.cart_Items = new MatTableDataSource<any>(cartItems);
        });
      } else {
        this.orderHistoryData = [];
        this.isOrderHistoryReceived = false;
      }
    });
  }

  swipe(event) {
    if (this.selectedTab === 0 && event.type === "swiperight") {
      return;
    }
    if (
      this.selectedTab + 1 === this.status.length &&
      event.type === "swipeleft"
    ) {
      return;
    }
    switch (event.type) {
      case "swipeleft":
        this.selectedTab += 1;
        break;
      case "swiperight":
        this.selectedTab -= 1;
        break;
    }
  }

  getTotalCost(element, index) {
    return element.cart_Items.filteredData
      .map((t) => parseInt(t.itemPrice))
      .reduce((acc, value) => acc + value, 0);
  }

  onSearchKey(e) {}
  clearSearchValues() {}
}
