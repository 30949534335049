import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoginSignupService } from "../service/login-signup-service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessagingService } from "../service/messaging.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-login-signup-form",
  templateUrl: "./login-signup-form.component.html",
  styleUrls: ["./login-signup-form.component.css"],
})
export class LoginSignupFormComponent implements OnInit {
  submitted: Boolean = false;
  hide = true; // Login page password
  hide1 = true; // Signup page password
  hide2 = true; // signup page confirm password
  hide3 = true; // Reset window new password
  hide4 = true; // Reset window confirm password
  loginResponse: boolean;
  signupResponse: boolean;
  isLoggedIn: boolean;
  selectedTab: number = 0;
  innerWidth: any;
  selectedValue: string;

  deviceToken: string;
  @ViewChild("login", { static: true })
  login: TemplateRef<any>;
  @ViewChild("forgotPasswordDialog", { static: true })
  forgotPasswordDialog: TemplateRef<any>;
  @ViewChild("aboutUsDialog", { static: true })
  aboutUsDialog: TemplateRef<any>;

  signin: FormGroup = new FormGroup({
    phoneNumber: new FormControl("", [
      Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
    ]),
    password: new FormControl("", [Validators.required, Validators.min(3)]),
  });
  signup = this.formBuilder.group(
    {
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
          ),
          Validators.minLength(8),
        ],
      ],
      confirmpassword: ["", [Validators.required]],
    },
    {
      validator: this.MustMatch("password", "confirmpassword"),
    }
  );

  forgotPassword: FormGroup = new FormGroup({
    // phoneNumber: new FormControl("", [
    //   Validators.required,
    //   Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
    // ]),
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  resetNewPassword = this.formBuilder.group(
    {
      temporaryPassword: ["", Validators.required],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
          ),
        ],
      ],
      confirmNewPassword: ["", [Validators.required]],
    },
    {
      validator: this.MustMatch("newPassword", "confirmNewPassword"),
    }
  );

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public service: LoginSignupService,
    public _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public messagingService: MessagingService
  ) {
    // this.messagingService.requestPermission();
  }

  ngOnInit(): void {
    this.isLoggedIn = false;
    this.loginResponse = false;
    this.signupResponse = false;
    // Getting login details from local storage.
    let Customer_sessionData = JSON.parse(
      localStorage.getItem("Customer_sessionData")
    );
    if (Customer_sessionData != null) {
      setTimeout(() => {
        this.messagingService.deviceToken.subscribe((token) => {
          this.deviceToken = (token === null ? Customer_sessionData.username + "_noDeviceIdGenerated" : token);;
        });
        Customer_sessionData.deviceId = this.deviceToken;

        this.service
          .isLoggedIn(Customer_sessionData)
          .subscribe((returnValue) => {
            if (returnValue == "Logged In") {
              this.isLoggedIn = true;
              // If the request is from any other login required pages then redirect it back.
              this.router.navigate(["/homePage/shopDetailForm"]);
            } else {
              localStorage.removeItem("shopId");
              localStorage.removeItem("source");
              localStorage.removeItem("Customer_sessionData");
              localStorage.removeItem("HomePageErrorCode");
              localStorage.removeItem("LoginPageErrorCode");
              localStorage.removeItem("cartItemList");
              this.isLoggedIn = true;
              this.router.navigate(["/"]);
            }
          });
      }, 2000);
    } else {
      this.isLoggedIn = true;
      setTimeout(() => {
        this.messagingService.deviceToken.subscribe((token) => {
          this.deviceToken = token;
        });
      }, 2000);
    }
  }
  ngAfterViewInit() {
    this.innerWidth = window.innerWidth;
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  goBack() {
    // console.log('goBack URL: ' + this.router.url);
    this.router.navigate(["/homePage/shopDetailForm"]);
  }

  onResize(event) {
    if (window.innerWidth < 768) {
      this.dialog.closeAll();
    }
  }

  swipe(event) {
    if (this.selectedTab === 0 && event.type === "swiperight") {
      return;
    }
    if (this.selectedTab + 1 === 2 && event.type === "swipeleft") {
      return;
    }
    switch (event.type) {
      case "swipeleft":
        this.selectedTab += 1;
        break;
      case "swiperight":
        this.selectedTab -= 1;
        break;
    }
  }

  onLoginSubmit() {
    if (this.signin.valid) {
      this.loginResponse = true;
      // console.log("form submitted");
      let loginDetails = {
        phoneNumber: this.signin.controls["phoneNumber"].value,
        password: this.signin.controls["password"].value,
        deviceToken:
          this.deviceToken === null
            ? this.signin.controls["phoneNumber"].value + "_noDeviceIdGenerated"
            : this.deviceToken,
      };
      this.service.login(loginDetails).subscribe((result: string) => {
        let responseData = result;
        if (responseData == "Login Failed") {
          this._snackBar.open("Please enter valid credentials", null, {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
          this.loginResponse = false;
        } else {
          this.dialog.closeAll();
          localStorage.setItem(
            "Customer_sessionData",
            JSON.stringify(JSON.parse(responseData)[0])
          );
          this.loginResponse = false;
          this.router.navigate([
            "/homePage/shopDetailForm",
            {
              state: { example: "data" },
            },
          ]);
          this.signin.reset();
        }
      });
      // this.router.navigate(['homeScreen/itemHomePage']);
      // this.signin.reset();
    }
  }

  onSignupSubmit() {
    if (this.signup.valid) {
      this.signupResponse = true;
      let deviceToken = "";
      this.messagingService.deviceToken.subscribe((token) => {
        deviceToken = token;
      });
      let signupDetails = {
        name: this.signup.controls["name"].value,
        email: this.signup.controls["email"].value,
        mobileNumber: this.signup.controls["mobileNumber"].value,
        password: this.signup.controls["password"].value,
        deviceToken:
          deviceToken === null
            ? this.signin.controls["phoneNumber"].value + "_noDeviceIdGenerated"
            : this.deviceToken,
      };
      this.service.signup(signupDetails).subscribe((result: string) => {
        let responseData = result;
        this.signupResponse = false;
        if (responseData == "Mobile Number Already Registered") {
          this._snackBar.open(responseData, null, {
            duration: 2500,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        } else if (responseData == "Email Already Registered") {
          this._snackBar.open(responseData, null, {
            duration: 2500,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        } else {
          this._snackBar.open("Registration successfull.", null, {
            duration: 2500,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
          this.dialog.closeAll();
          localStorage.setItem(
            "Customer_sessionData",
            JSON.stringify(JSON.parse(responseData)[0])
          );
          this.router.navigate(["/homePage/shopDetailForm"]);
          this.signup.reset();
        }
      });
    }
  }
  loginBtn() {
    // Closing all dialogs if any are open
    this.dialog.closeAll();
    this.dialog.open(this.login, {
      width: "600px",
      autoFocus: false,
    });
  }

  resetPassword() {
    this.selectedValue = "reset";
    this.dialog.closeAll();
    this.forgotPassword.reset();
    const dialogRef = this.dialog.open(this.forgotPasswordDialog, {
      disableClose: true,
      width: "400px",
      autoFocus: false,
    });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result != undefined) {
    //     if (result == "Reset") {
    //       this.dialog.closeAll();
    //     }
    //   }
    // });
  }

  confirmReset() {
    this.selectedValue = "load";
    this.service
      .resetPassword({ email: this.forgotPassword.controls.email.value })
      .subscribe((result) => {
        // Password Reset Successfull
        // Password Reset Failed
        // Email Not Sent
        if (result == "Password Reset Successfull") {
          this.selectedValue = "finished";
        } else if (result == "Email Not sent") {
          this.selectedValue = "reset";
          this._snackBar.open(
            "There is some error! Please try after sometime.",
            null,
            {
              duration: 2000,
              horizontalPosition: "center",
              verticalPosition: "bottom",
            }
          );
        } else if (result == "Email ID does not exist") {
          this.selectedValue = "reset";
          this._snackBar.open(
            "Please check the email id you have entered",
            null,
            {
              duration: 2000,
              horizontalPosition: "center",
              verticalPosition: "bottom",
            }
          );
        }
      });
  }

  onResetNewPasswordConfirm() {
    // customer_update_password.php
    let deviceToken = "";
    this.messagingService.deviceToken.subscribe((token) => {
      deviceToken = token;
    });
    let data = {
      email: this.forgotPassword.controls.email.value,
      temporaryPassword: this.resetNewPassword.controls.temporaryPassword.value,
      newPassword: this.resetNewPassword.controls.newPassword.value,
      deviceToken:
        deviceToken === null
          ? this.signin.controls["phoneNumber"].value + "_noDeviceIdGenerated"
          : this.deviceToken,
    };
    this.service.confirmReset(data).subscribe((result: any) => {
      if (result == "Incorrect temporary password") {
        this.selectedValue = "finished";
        this._snackBar.open(
          "Please enter the correct password sent to your mail",
          null,
          {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          }
        );
      } else {
        this.dialog.closeAll();
        this.resetNewPassword.reset();
        this.signin.reset();
        this.forgotPassword.reset();
        localStorage.setItem(
          "Customer_sessionData",
          JSON.stringify(JSON.parse(result)[0])
        );
        this.loginResponse = false;
        this.router.navigate(["/homePage/shopDetailForm"]);
      }
    });
  }

  aboutUsBtn() {
    // Closing all dialogs if any are open
    this.dialog.closeAll();
    this.dialog.open(this.aboutUsDialog, {
      // width: "600px",
      autoFocus: false,
    });
  }
}
