import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackComponent implements OnInit {
  feedbackDetails: FormGroup = new FormGroup({
    feedback: new FormControl("", [Validators.required]),
  });
  url: string;
  mailBody: string;
  constructor() {}

  ngOnInit() {
    // this.mailBody =
    //   "Details ---> Name:" +
    //   JSON.parse(localStorage.getItem("Customer_sessionData")).customer_name +
    //   " ,Mobile number: " +
    //   JSON.parse(localStorage.getItem("Customer_sessionData")).username +
    //   " (Please mention the order ID for fast reply from us)";
    // this.url =
    //   "https://mail.google.com/mail/?view=cm&fs=1&to=dhinasinangadi@gmail.com&su=Feedback regarding the order placed.&body=" +
    //   this.mailBody;
  }

  sendFeedback(){
    
  }
}
