import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CheckoutComponent } from "src/app/home-page/checkout/checkout.component";
import { HomePageService } from "src/app/service/home-page.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-shopitems",
  templateUrl: "./shopitems.component.html",
  styleUrls: ["./shopitems.component.css"],
})
export class ShopitemsComponent implements OnInit {
  itemList: any;
  finalCartList: any;
  itemsLoaded: boolean;
  cartItemsCount: number;
  itemCategories: any;
  selectedTab: number;
  initialResult: any;
  @ViewChild("clearCartDialog", { static: true })
  clearCartDialog: TemplateRef<any>;
  @ViewChild("goBackDialog", { static: true })
  goBackDialog: TemplateRef<any>;
  @ViewChild("generalDialog", { static: true })
  generalDialog: TemplateRef<any>;
  isSearching: boolean;
  dialogData: any = {
    title: "",
    content: "",
    note: "",
    imagePath: "",
  };
  isSearchResultEmpty: boolean;

  constructor(
    public service: HomePageService,
    public router: Router,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    this.itemList = [];
    this.itemsLoaded = true;
    this.itemCategories = [];
    this.selectedTab = 0;
    this.initialResult = [];
    this.finalCartList = [];
    this.cartItemsCount = null;
    this.getItemList();
  }

  openBottomSheet(): void {
    // this.finalCartList = [];
    // this.itemList.forEach((element1) => {
    //   element1.forEach((element2) => {
    //     // this.finalCartList = element2.filter((x) => x.itemPrice != undefined);
    //     if (element2.isItemAdded == true) {
    //       this.finalCartList.push(element2);
    //     }
    //   });
    // });
    // console.table(this.finalCartList);
    this.finalCartList = this.finalCartItemList();
    if (this.finalCartList.length == 0) {
      this._snackBar.open("Please add some items into the cart!", null, {
        duration: 2000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } else {
      this.service.finalItemCartListValue(this.finalCartItemList());
      // localStorage.setItem(
      //   "itemCategories",
      //   JSON.stringify(this.itemCategories)
      // );
      this._bottomSheet.open(CheckoutComponent);
    }
  }

  getItemList() {
    let ssv = JSON.parse(localStorage.getItem("Customer_sessionData"));
    let shopID: any;
    this.service.shopDetail.subscribe((result: any) => {
      if (result.shopDetails == undefined) {
        shopID = JSON.parse(localStorage.getItem("shopDetails"))["shopid"];
      } else {
        shopID = result.shopDetails.shopid;
        localStorage.setItem("shopDetails", JSON.stringify(result.shopDetails));
      }
    });
    let data = {
      shopid: shopID,
      customerID: ssv.customerid,
      token: ssv.token,
    };
    let lsv: any = [];
    lsv = JSON.parse(localStorage.getItem("cartItemList"));
    if (lsv != null) {
      this.service.getItemList(data).subscribe((result2: any) => {
        result2.forEach((element) => {
          // element.itemPrice = element.selling_price.split(",")[0];
          // element.quantityOption = element.item_quantities.split(",")[0];

          element.itemPrices = Object.values(JSON.parse(element.qty_price));
          element.quantityOptions = Object.keys(JSON.parse(element.qty_price));
          element.itemPrice = element.itemPrices[0];
          element.quantityOption = element.quantityOptions[0];
        });

        // for (let i = 0; i < result2.length; i++) {
        //   this.initialResult.push({
        //     ...result2[i],
        //     ...lsv.find((itmInner) => itmInner.item_id === result2[i].item_id),
        //   });
        // }

        result2.forEach((element) => {
          this.initialResult.push({
            ...element,
            ...lsv.find((itmInner) => itmInner.item_id === element.item_id),
          });
        });

        this.alterItemDetails(this.initialResult);
        this.itemsLoaded = false;
      });
      this.cartItemsCount = lsv.length;
    } else {
      this.service.getItemList(data).subscribe((result: any) => {
        // this.itemList = result;
        this.initialResult = result;
        this.initialResult.forEach((element) => {
          element.initialQuantity = 1;
          element.isItemAdded = false;
          // element.itemPrice = element.selling_price.split(",")[0];
          // element.quantityOption = element.item_quantities.split(",")[0];

          element.itemPrices = Object.values(JSON.parse(element.qty_price));
          element.quantityOptions = Object.keys(JSON.parse(element.qty_price));
          element.itemPrice = element.itemPrices[0];
          element.quantityOption = element.quantityOptions[0];
        });
        this.alterItemDetails(this.initialResult);
        this.itemsLoaded = false;
      });
    }
  }

  alterItemDetails(result) {
    this.itemList = [];
    this.itemCategories = result
      .map((item) => item.item_category)
      .filter((value, index, self) => self.indexOf(value) === index);
    localStorage.setItem("itemCategories", JSON.stringify(this.itemCategories));

    this.itemCategories.forEach((element1: string) => {
      let filterResult = result.filter(
        (x) => x.item_category.toUpperCase() === element1.toUpperCase()
      );
      this.itemList.push(filterResult);
    });
    let lsv: any = localStorage.getItem("cartItemList");
    if (lsv != null) {
      this.itemCategories = JSON.parse(localStorage.getItem("itemCategories"));
    }
  }

  clearCart() {
    const dialogRef = this.dialog.open(this.clearCartDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          localStorage.removeItem("cartItemList");
          this.cartItemsCount = null;
          this.ngOnInit();
        }
      }
    });
  }

  addItemsToCart(data) {
    data.initialQuantity = 1;
    data.isItemAdded = true;
    this.cartItemsCount++;
    this.finalCartItemList();
  }

  decreaseCount(data) {
    if (data.initialQuantity > 1) {
      data.initialQuantity--;
      let value = data.itemPrice;
      if (data.initialQuantity > 1) {
        let amount =
          parseInt(value) - parseInt(value) / (data.initialQuantity + 1);
        data.itemPrice = amount.toString();
      } else {
        let amount = parseInt(value) / 2;
        data.itemPrice = amount.toString();
      }
    } else {
      if (this.cartItemsCount == 1) {
        data.isItemAdded = false;
        localStorage.removeItem("cartItemList");
        this.cartItemsCount = null;
      } else {
        data.isItemAdded = false;
        this.cartItemsCount--;
      }
    }
    this.finalCartItemList();
  }
  increaseCount(data) {
    data.initialQuantity++;
    let value = data.itemPrice;
    if (data.initialQuantity == 2) {
      let amount = parseInt(value) + parseInt(value);
      data.itemPrice = amount.toString();
    } else {
      let amount =
        (parseInt(value) / (data.initialQuantity - 1)) * data.initialQuantity;
      data.itemPrice = amount.toString();
    }
    this.finalCartItemList();
  }

  finalCartItemList() {
    this.finalCartList = [];
    this.itemList.forEach((element1) => {
      element1.forEach((element2) => {
        // this.finalCartList = element2.filter((x) => x.itemPrice != undefined);
        if (element2.isItemAdded == true) {
          this.finalCartList.push(element2);
          localStorage.setItem("cartItemList", JSON.stringify(this.finalCartList));
        }
      });
    });
    return this.finalCartList;
  }

  setItemPrice(data) {
    data.itemPrice = data.selling_price.split(",")[0];
  }

  changeAmout(data, amountIndex) {
    // console.log(amountIndex);
    data.initialQuantity = 1;
    data.quantityOption = data.quantityOptions[amountIndex];
    data.itemPrice = data.itemPrices[amountIndex];
  }

  filterItem(value) {
    if (!value) {
      this.getItemList();
    } // when nothing has typed
    this.itemList = Object.assign([], this.itemList).filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }

  swipe(event) {
    // console.log(event);
    if (this.selectedTab === 0 && event.type === "swiperight") {
      return;
    }
    if (
      this.selectedTab + 1 === this.itemCategories.length &&
      event.type === "swipeleft"
    ) {
      return;
    }
    switch (event.type) {
      case "swipeleft":
        this.selectedTab += 1;
        break;
      case "swiperight":
        this.selectedTab -= 1;
        break;
    }
  }

  changeSelectedTabIndex(event) {
    // console.log(event);
    this.selectedTab = event.index;
  }

  onSearchKey(value: string) {
    // console.log(value);
    let searchListEnglish = this.initialResult.filter((item) =>
      item.keywords.toUpperCase().match(value.toUpperCase())
    );

    // let searchListEnglish = this.initialResult.filter((item) => {
    //   return value.indexOf(item.keywords.split(",")) >= 0;
    // });

    // let searchListEnglish = this.initialResult.filter((item) =>
    //   item.keywords
    //     .split(",")
    //     .forEach((element) => element.toUpperCase().includes(value.toUpperCase()))
    // );

    let searchListKannada = this.initialResult.filter((item) =>
      item.keywords.toUpperCase().match(value.toUpperCase())
    );
    // let searchList = this.initialResult.filter(o =>
    //   Object.keys(o).some(k => o[k].toLowerCase().includes(value.toLowerCase())));

    if (searchListEnglish.length != 0) {
      this.isSearching = true;
      this.isSearchResultEmpty = false;
      this.alterItemDetails(searchListEnglish);
    } else {
      this.isSearchResultEmpty = true;
    }
    if (searchListKannada.length != 0) {
      this.isSearching = true;
      this.isSearchResultEmpty = false;
      this.alterItemDetails(searchListKannada);
    } else {
      this.isSearchResultEmpty = true;
    }
    if (value == "") {
      this.clearSearchValues();
    }
  }

  clearSearchValues() {
    this.isSearching = false;
    this.isSearchResultEmpty = false;
    this.alterItemDetails(this.initialResult);
  }

  goBack() {
    const dialogRef = this.dialog.open(this.goBackDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.router.navigate(["/homePage/shopDetailForm"]);
        }
      }
    });
  }

  showItemImage(val: any): void {
    this.dialogData = {
      title: val.item_name + " / " + val.item_kname,
      content: "",
      note: "",
      imagePath: val.item_imgpath,
    };
    const dialogRef = this.dialog.open(this.generalDialog, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.router.navigate(["/homePage/shopDetailForm"]);
        }
      }
    });
  }
}
