import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { HomePageService } from "src/app/service/home-page.service";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe, formatDate } from "@angular/common";
import { sha256, sha224 } from "js-sha256";
import { PaymentPageService } from "src/app/service/payment-options.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { CheckoutComponent } from "../checkout/checkout.component";

@Component({
  selector: "app-payment-option-details",
  templateUrl: "./payment-option-details.component.html",
  styleUrls: ["./payment-option-details.component.css"],
})
export class PaymentOptionDetailsComponent implements OnInit {
  panelOpenState: boolean;
  isDeliveryAmountReceived: boolean;
  isPaymentSelected: boolean;
  gpayVPA: boolean = null;
  phonepeVPA: boolean = null;
  patmVPA: boolean = null;
  isQuickDelivery: boolean;
  isDeliveryExtraChargable: boolean;
  isSavedAddress: boolean;
  isTodayDelivery: boolean;

  today: any = new Date();
  minDate: Date = new Date(new Date().setDate(new Date().getDate()));
  maxDate: Date = new Date(new Date().setDate(new Date().getDate() + 1));

  paymentAndOrderDetails: FormGroup = new FormGroup({
    address: new FormControl("", Validators.required),
    deliveryDate: new FormControl("", [
      Validators.required,
      Validators.min(this.minDate.getDate()),
      Validators.min(this.maxDate.getDate()),
    ]),
    deliverTime: new FormControl({ value: "", disabled: false }, [
      Validators.required,
    ]),
    detailedAddress: new FormControl("", [Validators.required]),
    paymentType: new FormControl("", [Validators.required]),
  });

  VPADetails = this.formBuilder.group({
    vpa: ["", [Validators.required]],
  });

  // Mat table stuffs
  cartList: any;
  displayedColumns: string[] = [
    "item_id",
    "item_name",
    "cost_price",
    "itemPrice",
  ];
  dataSource: MatTableDataSource<any>;
  cartAmount: any;
  areaList: any;
  deliveryChargeAmount: any;
  extraDeliveryAmount: any;
  saveAddress: boolean;
  eligible: boolean;
  timeSlotOptions: any;
  orderResponse: boolean;
  lsv = JSON.parse(localStorage.getItem("Customer_sessionData"));

  constructor(
    private formBuilder: FormBuilder,
    public service: HomePageService,
    public paymentPageService: PaymentPageService,
    public router: Router,
    public _snackBar: MatSnackBar,
    public _bottomSheet: MatBottomSheet
  ) {
    this.panelOpenState = false;
    // console.log(this.today.toLocaleTimeString());
  }

  ngOnInit() {
    // Shop Names
    this.areaList = [];
    this.isTodayDelivery = true;
    this.orderResponse = false;
    this.timeSlotOptions = [
      "Morning 10 AM - 1 PM",
      "Afternoon 2 PM - 4PM",
      "Evening 5 PM - 8 PM",
    ];
    this.isDeliveryAmountReceived = false;
    this.isPaymentSelected = false;
    this.deliveryChargeAmount = 0;
    this.extraDeliveryAmount = 0;

    var date1 = new Date(this.today);
    var date2 = new Date(this.today.setHours(19, 0, 0, 0));

    if (date1 > date2) {
      this.isTodayDelivery = false;
      this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
      this.maxDate = new Date(new Date().setDate(new Date().getDate() + 2));
      this.paymentAndOrderDetails.controls.deliveryDate.setValidators([
        Validators.required,
        Validators.min(this.minDate.getDate()),
        Validators.min(this.maxDate.getDate()),
      ]);
    }

    this.eligible = this.lsv.ncod.includes(JSON.parse(localStorage.getItem("shopDetails"))["shopid"]);

    this.lsv.customer_address != ""
      ? (this.saveAddress = true)
      : (this.saveAddress = false);
    this.paymentAndOrderDetails.controls.detailedAddress.setValue(
      this.lsv.customer_address != "" ? this.lsv.customer_address : ""
    );
    this.service.getDeliveryList().subscribe((result: any) => {
      result.forEach((element) => {
        this.areaList.push(element.areaname);
      });
    });

    this.service.currentItems.subscribe((result: any) => {
      if (result.length == 0) {
        let lsv = JSON.parse(localStorage.getItem("cartItemList"));
        this.cartList = lsv;
      } else {
        this.cartList = result;
      }
      this.cartList.forEach((element) => {
        let initialCost = parseInt(element.itemPrice) / element.initialQuantity;
        element.initialCost = initialCost;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.cartAmount = this.getCartAmount();
    });
  }

  openBottomSheet(): void {
    let cartItems = JSON.parse(localStorage.getItem("cartItemList"));
    this.service.finalItemCartListValue(cartItems);
    this._bottomSheet.open(CheckoutComponent);
  }

  /* Get delivery charge */
  onAreaChange() {
    this.isDeliveryAmountReceived = true;
    let srcdst = {
      src: localStorage.getItem("source"),
      dst: this.paymentAndOrderDetails.controls["address"].value,
    };
    this.service.deliveryCharge(srcdst).subscribe((result: any) => {
      this.isDeliveryAmountReceived = false;
      this.isQuickDelivery = false;
      this.extraDeliveryAmount = 0;
      let cartAmount = this.getCartAmount();
      if (cartAmount > 500) {
        this.deliveryChargeAmount = parseInt(result);
        this.isDeliveryExtraChargable = false;
      } else {
        this.deliveryChargeAmount = parseInt(result) + 25;
        this.isDeliveryExtraChargable = true;
      }
    });
  }

  /** Gets the total cost of all transactions. */
  getCartAmount() {
    return this.cartList
      .map((t) => parseInt(t.itemPrice))
      .reduce((acc, value) => acc + value, 0);
  }

  getGST() {
    return this.cartAmount * 0.025;
  }

  getDeliveryCharge() {
    return 13;
  }

  getTotalAmount() {
    // return this.getCartAmount() + this.getGST() + this.getDeliveryCharge();
    return this.getCartAmount() + this.deliveryChargeAmount;
  }

  onTimeSlotChange() {
    this.paymentAndOrderDetails.controls.deliverTime.setValue("");
    var date = new Date();

    if (
      this.paymentAndOrderDetails.controls.deliveryDate.value.toLocaleDateString() !=
      date.toLocaleDateString()
    ) {
      // console.log("Tomorrow");
      this.timeSlotOptions = [
        "Morning 10 AM - 1 PM",
        "Afternoon 2 PM - 4PM",
        "Evening 5 PM - 8 PM",
      ];
      if(this.isQuickDelivery){
        this.deliveryChargeAmount = this.deliveryChargeAmount - 30;
      }
      this.isTodayDelivery = false;
      this.isQuickDelivery = false;
      this.paymentAndOrderDetails.controls.deliverTime.enable();

      let cartAmount = this.getCartAmount();
      if (cartAmount !< 500) {
      }
      // this._snackBar.open(this.timeSlotOptions.toString(), null, {
      //   duration: 3000,
      // });
    } else {
      // let dateAndTime = datePipe.transform(date, 'dd-MM-yyyy hh:mm:ss TT', 'en_US');
      // this.today.toLocaleString() > this.today.toLocaleDateString() + " 6:00:00 PM";
      // this._snackBar.open("Entered else block", null, {
      //   duration: 3000,
      // });
      this.isTodayDelivery = true;
      var date1 = new Date(date);
      var date2 = new Date(date.setHours(1, 0, 0, 0));
      var date3 = new Date(date.setHours(12, 0, 0, 0));
      var date4 = new Date(date.setHours(15, 0, 0, 0));
      var date5 = new Date(date.setHours(19, 0, 0, 0));

      // var date2 = new Date(date.toLocaleDateString() + " 01:00:00 AM");
      // var date3 = new Date(date.toLocaleDateString() + " 12:00:00 PM");
      // var date4 = new Date(date.toLocaleDateString() + " 03:00:00 PM");
      // var date5 = new Date(date.toLocaleDateString() + " 07:00:00 PM");
      if (date1 > date2 && date1 <= date3) {
        this.timeSlotOptions = [
          "Morning 10 AM - 1 PM",
          "Afternoon 2 PM - 4PM",
          "Evening 5 PM - 8 PM",
        ];
      } else if (date1 > date3 && date1 <= date4) {
        this.timeSlotOptions = ["Afternoon 2 PM - 4PM", "Evening 5 PM - 8 PM"];
      } else if (date1 > date4 && date1 <= date5) {
        this.timeSlotOptions = ["Evening 5 PM - 8 PM"];
      }
    }
  }

  quickDelivery(ev) {
    if (ev) {
      // this.paymentAndOrderDetails.controls.deliverTime.setValue(
      //   "Quick Delivery"
      // );
      this.isQuickDelivery = true;
      this.paymentAndOrderDetails.controls.deliverTime.disable();
      this.paymentAndOrderDetails.controls.deliverTime.setValue("");
      // let initialDeliveryCharge = this.deliveryChargeAmount;
      let cartAmount = this.getCartAmount();
      if (cartAmount > 500) {
        // this.deliveryChargeAmount = Math.floor(
        //   this.deliveryChargeAmount +
        //     Math.floor(this.deliveryChargeAmount * 0.5)
        // );
        this.deliveryChargeAmount = Math.floor(this.deliveryChargeAmount + 30);
        // this.extraDeliveryAmount = Math.floor(
        //   Math.floor(parseInt(initialDeliveryCharge) * 0.5)
        // );
        this.extraDeliveryAmount = 30;
      } else {
        // Only add half of the delivery amount without considering cart amount
        // this.deliveryChargeAmount = Math.floor(
        //   this.deliveryChargeAmount +
        //     Math.floor(this.deliveryChargeAmount - 25) * 0.5
        // );
        this.deliveryChargeAmount = Math.floor(this.deliveryChargeAmount + 30);
        // this.extraDeliveryAmount = Math.floor(
        //   Math.floor(parseInt(initialDeliveryCharge) - 25) * 0.5
        // );
        this.extraDeliveryAmount = 30;
      }
    } else {
      this.paymentAndOrderDetails.controls.deliverTime.enable();
      this.paymentAndOrderDetails.controls.deliverTime.reset();
      this.isQuickDelivery = false;
      // this.deliveryChargeAmount =
      //   this.deliveryChargeAmount - parseInt(this.extraDeliveryAmount);
      this.deliveryChargeAmount = this.deliveryChargeAmount - 30;
    }
  }

  normalPayment() {
    this.orderResponse = true;
    var datePipe = new DatePipe("en-US");
    let deliveryDate = datePipe.transform(
      this.paymentAndOrderDetails.controls["deliveryDate"].value,
      "MM-dd-yyyy"
    );

    let cartItems: any = JSON.parse(localStorage.getItem("cartItemList"));
    cartItems.forEach((element) => {
      delete element.item_imgpath;
      delete element.item_category;
      delete element.qty_price;
      delete element.itemPrices;
      delete element.quantityOptions;
      delete element.item_imgpath;
    });
    let payment_details = {
      cartItems: JSON.stringify(cartItems),
      customerid: JSON.parse(localStorage.getItem("Customer_sessionData"))
        .customerid,
      token: JSON.parse(localStorage.getItem("Customer_sessionData")).token,
      shodID: JSON.parse(localStorage.getItem("shopDetails"))["shopid"],
      source: localStorage.getItem("source"),
      destination: this.paymentAndOrderDetails.controls.address.value,
      deliverDate: deliveryDate,
      deliverTime:
        this.paymentAndOrderDetails.controls.deliverTime.value == ""
          ? "Quick Delivery"
          : this.paymentAndOrderDetails.controls.deliverTime.value,
      deliveryAddress: this.paymentAndOrderDetails.controls.detailedAddress
        .value,
      paymentType: this.paymentAndOrderDetails.controls.paymentType.value,
      cartAmount: this.getCartAmount(),
      deliveryAmount: this.deliveryChargeAmount,
      saveAddress: this.saveAddress,
    };
    // console.table(payment_details);
    this.service.payment(payment_details).subscribe((response) => {
      // console.log(response);
      if (response === "Ordered Successfully") {
        this.orderResponse = false;
        this.lsv["customer_address"] = this.paymentAndOrderDetails.controls[
          "detailedAddress"
        ].value;
        this._snackBar.open("Order Successful.", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        localStorage.removeItem("Customer_sessionData");
        localStorage.setItem("Customer_sessionData", JSON.stringify(this.lsv));
        localStorage.removeItem("cartItemList");
        this.router.navigate(["/homePage/orderHistory"]);
      } else if (response === "Failure") {
        this.orderResponse = false;
        this._snackBar.open(
          "Order Unsuccessful!. Please try after sometime.",
          null,
          {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          }
        );
      }
    });
  }

  // VPA verification code
  gpayVPAVerify() {
    if (this.paymentAndOrderDetails.valid) {
    } else {
      this.validateAllFormFields(this.paymentAndOrderDetails);
    }
  }

  phonepeVPAVerify() {
    // window.btoa({Object})
    if (this.paymentAndOrderDetails.valid) {
      let MID = "M2306160483220675579140";
      let VPA = this.VPADetails.controls.vpa.value;
      // SHA256("/v3/vpa/{merchantId}/{vpa}/validate" + saltKey)
      let str =
        "/v3/vpa/" +
        MID +
        "/" +
        VPA +
        "/validate" +
        "8289e078-be0b-484d-ae60-052f117f8deb";
      let SHAstr = sha256(str) + "###1";
      this.paymentPageService
        .verifyPhonepeVPA(MID, VPA, SHAstr)
        .subscribe((result) => {
          // console.log(result);
          if (result.code == "SUCCESS") {
            this.phonepeVPA = true;
          } else {
            this.phonepeVPA = false;
          }
        });
    } else {
      this.validateAllFormFields(this.paymentAndOrderDetails);
    }
  }

  patmVPAVerify() {
    if (this.paymentAndOrderDetails.valid) {
    } else {
      this.validateAllFormFields(this.paymentAndOrderDetails);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }
}

// Payment request web integration version 3
/*
googlePayPayment() {}
  phonepePayment() {
    let payload = {
      merchantId: "M2306160483220675579140",
      transactionId: "TX"+ Math.random().toString().slice(2),
      merchantUserId: "U123456789",
      amount: 100,
      merchantOrderId: "OD1234",
      mobileNumber: "7892962454",
      message: "payment for order placed OD1234",
      subMerchant: "DemoMerchant",
      email: "amit75@gmail.com",
      shortName: "Amit",
    };

    let request = window.btoa(JSON.stringify(payload));
    let xverify = sha256(request+"/v3/debit"+"8289e078-be0b-484d-ae60-052f117f8deb") + "###1";
    this.paymentPageService.phonepe(request, xverify).subscribe(result => {
      console.log(result);
    });

  }
  paytmPayment() {}
*/
