import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserProfileService } from "src/app/service/user-profile.service";
import { Router } from "@angular/router";
import { MessagingService } from "src/app/service/messaging.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  hide1: boolean = true;
  hide2: boolean = true;
  hide3: boolean = true;
  lsv = JSON.parse(localStorage.getItem("Customer_sessionData"));

  changePassword = this.formBuilder.group(
    {
      oldPassword: ["", Validators.required],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
          ),
        ],
      ],
      confirmNewPassword: ["", [Validators.required]],
    },
    {
      validator: this.MustMatch("newPassword", "confirmNewPassword"),
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    public _snackBar: MatSnackBar,
    private service: UserProfileService,
    private router: Router,
    public messagingService: MessagingService
  ) {}

  ngOnInit(): void {}

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  changePasswordBtn() {
    /*
  customer_change_password.php

  customerid;
  phoneNumber;
  password; 
  newPassword;
  deviceToken;
  */

    let data = {
      oldPassword: this.changePassword.controls.oldPassword.value,
      newPassword: this.changePassword.controls.newPassword.value,
      phoneNumber: this.lsv["username"],
      token: this.lsv["token"],
      customerid: this.lsv["customerid"],
    };
    this.service.changePassword(data).subscribe((response) => {
      // Username/Password Does Not Match
      // Password Changed Successfully
      // User Authentication Failed
      //
      if (response == "Password Changed Successfully") {
        this._snackBar.open("Password Changed Successfully", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        this.router.navigate(["/homePage/userProfile"]);
      } else if (response == "Username/Password Does Not Match") {
        this._snackBar.open("Please check your old password", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      } else if (response == "User Authentication Failed") {
        this._snackBar.open("Please enter valid credentials", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        // localStorage.clear();
        localStorage.removeItem("shopId");
        localStorage.removeItem("source");
        localStorage.removeItem("Customer_sessionData");
        localStorage.removeItem("HomePageErrorCode");
        localStorage.removeItem("LoginPageErrorCode");
        localStorage.removeItem("cartItemList");
        this.router.navigate(["/"]);
      } else {
        this._snackBar.open("Please try after sometime", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    });
  }
}
