<!-- sm -->
<div class="d-block d-sm-block d-md-none d-lg-none d-xl-none p-2 w-100 h-100">
    <form [formGroup]="feedbackDetails">
        <h2 class="text-font-style">Send Feedback</h2>
        <small class="text-font-style">Tell us what we could be doing better, or anything you liked about our
            service.</small>
        <br><br>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label class="text-font-style">Feedback</mat-label>
            <textarea class="text-font-style" matInput placeholder="Type your suggestions..."
                formControlName="feedback"></textarea>
            <mat-hint class="text-font-style" sytle="margin-top: 1px">Let us know how can we improvise our service.</mat-hint>
            <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="feedbackDetails.controls['feedback'].invalid">Oops! type your
                feedback before
                submit</mat-error>
        </mat-form-field>

        <div class="container-fluid p-0">
            <p class="mt-3 text-font-style">If your feedback is related to any addition of items to your shop list, feel
                free to
                write us at
                <a class="text-font-style"
                [href]="url"
                    target="_black">dhinasinangadi@gmail.com</a>
            </p>
            <!-- <p><small class="text-font-style">Note*: Please specify the item name and brand</small></p> -->
            <br>
            <button class="info-sm col-12 p-2 btn btn-outline-warning bg-warning text-font-style" mat-raised-button
                (click)="feedbackDetails.valid ? sendFeedback(): null">Submit</button>
        </div>
    </form>
</div>

<!-- md, lg, xl -->
<div class="d-none d-md-block d-lg-block d-xl-block p-2 h-100">
    <div class="container">
        <mat-card>
            <form [formGroup]="feedbackDetails" class="container">
                <h2 class="text-font-style">Send Feedback</h2>
                <small class="text-font-style">Tell us what we could be doing better, or anything you liked about our
                    service.</small>
                <br><br>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="text-font-style">Feedback</mat-label>
                    <textarea class="text-font-style" matInput placeholder="Type your suggestions..."
                        formControlName="feedback"></textarea>
                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Let us know how can we improvise our service.</mat-hint>
                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="feedbackDetails.controls['feedback'].invalid">Oops! type
                        your feedback
                        before
                        submit</mat-error>
                </mat-form-field>

                <div class="container-fluid p-0">
                    <p class="mt-3 text-font-style">If your feedback is related to an order you have placed, feel free
                        to write us
                        at
                        <a class="text-font-style"
                            [href]="url"
                            target="_black">dhinasinangadi@gmail.com</a>
                    </p>
                    <br>
                    <div class="text-center">
                        <button class="col-3 p-2 btn btn-outline-warning bg-warning text-font-style" mat-raised-button
                            (click)="feedbackDetails.valid ? sendFeedback(): null">Submit</button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>