import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  imageChangedEvent: string;
  croppedImage: any;
  
  constructor(
    public _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.imageChangedEvent='';
    this.croppedImage='';
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
    this._snackBar.open("Failed to load image! Please try again", null, {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
  }
}
