<mat-card class="h-100 style-6 overflow-scroll mb-5 text-center">
    <mat-toolbar class="bg-white sticky-top shadow-sm">
        <mat-toolbar-row>
            <span class="text-font-style d-block d-sm-block d-md-none d-lg-none d-xl-none" style="font-size: xx-large;">Address Manager</span>
            <span class="text-font-style display-4 d-none d-md-block d-lg-block d-xl-block">Address Manager</span>
            <span style="flex: 1 1 auto;"></span>
            <button class="btn btn-outline-light" mat-icon-button routerLink="/homePage/userProfile/">
                <mat-icon aria-hidden="false">clear</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</mat-card>