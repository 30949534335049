import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatTableDataSource } from "@angular/material/table";
import { HomePageService } from "src/app/service/home-page.service";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  cartList: any;
  @ViewChild("proceedToPaymentDialog", { static: true })
  goBackDialog: TemplateRef<any>;
  displayedColumns: string[] = ["item_name", "cost_price", "itemPrice"];
  dataSource: MatTableDataSource<any>;
  isPaymentPage: boolean;

  constructor(
    public _bottomSheetRef: MatBottomSheetRef<CheckoutComponent>,
    public service: HomePageService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit() {
    if (this.router.url == "/homePage/paymentOptions") {
      this.isPaymentPage = true;
    } else {
      this.isPaymentPage = false;
    }
    this.service.currentItems.subscribe((result: any) => {
      this.cartList = result;
      result.forEach((element) => {
        let initialCost = parseInt(element.itemPrice) / element.initialQuantity;
        element.initialCost = initialCost;
      });
      this.dataSource = new MatTableDataSource<any>(result);
    });
    // this.dataSource = new MatTableDataSource<any>(this.finalCartList);
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  /** Gets the total cost of all transactions. */
  getTotalCost() {
    return this.cartList
      .map((t) => parseInt(t.itemPrice))
      .reduce((acc, value) => acc + value, 0);
  }

  closeBtm() {
    this._bottomSheetRef.dismiss();
  }

  proceedToPayment() {
    this._bottomSheetRef.dismiss();
    // this.cartList
    const dialogRef = this.dialog.open(this.goBackDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.router.navigate(["/homePage/paymentOptions"]);
        }
      }
    });
  }
}
