
<mat-toolbar color="primary" class="sticky-top">
    <mat-toolbar-row class="text-font-style" color="primary">Dhinasi Angadi
    </mat-toolbar-row>
</mat-toolbar>


<div *ngIf="statusCode == 'Not Online'" class="container">
   Not Online

</div>

<div *ngIf="statusCode == 400" class="container">400</div>
<div *ngIf="statusCode == 401" class="container">401</div>
<div *ngIf="statusCode == 403" class="container">403</div>
<div *ngIf="statusCode == 404" class="container">404</div>
<div *ngIf="statusCode == 405" class="container">405</div>
<div *ngIf="statusCode == 409" class="container">409</div>
<div *ngIf="statusCode == 500" class="container">500</div>
<div *ngIf="statusCode == 503" class="container">503</div>