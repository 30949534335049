import { Component, OnInit } from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Validators, FormBuilder } from "@angular/forms";
import { UserProfileService } from "src/app/service/user-profile.service";
import { Router } from "@angular/router";
import { MessagingService } from "src/app/service/messaging.service";

@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styleUrls: ["./profile-edit.component.css"],
})
export class ProfileEditComponent implements OnInit {
  imageChangedEvent: string;
  croppedImage: any;
  isProfileChangesDone: boolean;
  lsv = JSON.parse(localStorage.getItem("Customer_sessionData"));
  userProfile = this.formBuilder.group({
    name: [
      this.lsv["customer_name"] == "" ? "" : this.lsv["customer_name"],
      [Validators.required],
    ],
    email: [
      this.lsv["email"] == "" ? "" : this.lsv["email"],
      [Validators.required, Validators.email],
    ],
  });

  constructor(
    private formBuilder: FormBuilder,
    public _snackBar: MatSnackBar,
    private service: UserProfileService,
    private router: Router,
    public messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.imageChangedEvent='';
    this.croppedImage='';
    this.isProfileChangesDone=false;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
    this._snackBar.open("Failed to load image! Please try again", null, {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
  }

  saveProfileChanges() {
    this.isProfileChangesDone = true;
    let data = {
      name: this.userProfile.controls.name.value,
      email: this.userProfile.controls.email.value,
      image: this.croppedImage,
      token: this.lsv["token"],
      customerid: this.lsv["customerid"],
    };
    this.service.saveUserProfileData(data).subscribe((response) => {
    this.isProfileChangesDone = false;
      if (response == "Profile Details Updated Successfully") {
        this._snackBar.open("Profile Details Successfully Updated", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        this.lsv["email"] = this.userProfile.controls.email.value;
        this.lsv["customer_name"] = this.userProfile.controls.name.value;
        this.lsv["customer_profileimage"] =
          this.croppedImage != ""
            ? this.croppedImage
            : this.lsv["customer_profileimage"];

        localStorage.removeItem("Customer_sessionData");
        localStorage.setItem("Customer_sessionData", JSON.stringify(this.lsv));
        this.router.navigate(["/homePage/userProfile"]);
      } else if (response == "User Authentication Failed") {
        this._snackBar.open("Please try to login", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        this.router.navigate(["/"]);
      } else if (response == "Email Already Registered") {
        this._snackBar.open("email already registered", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      } else {
        this._snackBar.open("Update failed! Please try after sometime", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    });
  }
}
