<mat-toolbar color="primary" class="sticky-top">
    <mat-toolbar-row class="text-font-style justify-content-between p-2">
        <button mat-icon-button style="outline: 0;">
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
        </button>
        <!-- <span class="menu-spacer"></span> -->
        <p routerLink="/homePage/shopDetailForm" class="text-font-style mt-2 mb-0 ml-2" style="outline: 0; border: 0;">
            Dhinasi Angadi</p>
        <span class="flex-spacer"></span>
        <!-- <img src="./assets/icons/dhinasi_angadi-72x72.png" alt=""> -->
        <!-- <img src="./assets/icons/Dhinasi_Angadi_72x72.jpg" style="height: 54px; width: 75px; border-radius: 13px;"> -->
        <!-- <div class="flex-spacer d-none d-sm-block"></div> -->
        <button *ngIf="false" color="primary" mat-mini-fab>
            <mat-icon matSuffix>notifications_active</mat-icon>
        </button>
        <!-- <button (click)="goBack();" class="btn btn-outline-light bg-light" mat-button mat-mini-fab>
            <mat-icon color="red">power_settings_new</mat-icon>
        </button> -->
    </mat-toolbar-row>
</mat-toolbar>
<div class="container-fluid h-100 w-100 p-0">
    <mat-sidenav-container class="h-100 w-100">
        <mat-sidenav #sidenav class="navbar-homescreen-width">
            <mat-nav-list class="text-font-style">
                <!-- <div class="col-12 text-center d-block d-sm-block d-md-none d-lg-none d-xl-none"> -->
                <div class="col-12 text-center">
                    <img *ngIf="isProfileImageEmpty == ''" (click)="sidenav.toggle()" routerLink="/homePage/userProfile"
                        class="rounded-circle shadow userProfileImage" style="outline: 0;" draggable="false"
                        src="./assets/images/user_image.png">
                    <img *ngIf="isProfileImageEmpty != ''" (click)="sidenav.toggle()" routerLink="/homePage/userProfile"
                        class="rounded-circle shadow userProfileImage" style="outline: 0;" draggable="false"
                        [src]="isProfileImageEmpty">
                    <div class="d-inline">
                        <h3 class="mb-0 mt-3 mb-3 text-font-style">{{userName}}</h3>
                        <!-- <p><small>{{mobileNumber}}</small></p> -->
                    </div>
                </div>

                <!-- <mat-divider class="d-block d-sm-block d-md-none d-lg-none d-xl-none"></mat-divider> -->
                <a class="text-font-style" mat-list-item routerLink="/homePage/shopDetailForm"
                    (click)="sidenav.toggle();">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Home</p>
                        <mat-icon>home</mat-icon>
                    </div>
                </a>

                <!-- <a class="text-font-style" mat-list-item (click)="sidenav.toggle();goBack();">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Shop List</p>
                        <mat-icon>reorder</mat-icon>
                    </div>
                </a> -->

                <a class="text-font-style" mat-list-item routerLink="/homePage/orderHistory" (click)="sidenav.toggle()">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Orders</p>
                        <mat-icon>history</mat-icon>
                    </div>
                </a>
                <!-- 
                <a class="text-font-style" mat-list-item routerLink="/homePage/userProfile" (click)="sidenav.toggle()">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Account</p>
                        <mat-icon>account_circle</mat-icon>
                    </div>
                </a> -->
                <!-- 
                <a class="text-font-style" mat-list-item routerLink="/homePage/userProfile" (click)="sidenav.toggle()">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Settings</p>
                        <mat-icon>settings</mat-icon>
                    </div>
                </a> -->

                <a class="text-font-style" mat-list-item routerLink="/homePage/feedback" (click)="sidenav.toggle()">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Feedback</p>
                        <mat-icon>feedback</mat-icon>
                    </div>
                </a>

                <a class="text-font-style" mat-list-item routerLink="/homePage/faq" (click)="sidenav.toggle()">
                    <div class="w-100 d-flex justify-content-between">
                        <p>FAQ</p>
                        <mat-icon>question_answer</mat-icon>
                    </div>
                </a>

                <a class="text-font-style" mat-list-item routerLink="/homePage/about"
                    (click)="sidenav.toggle()">
                    <div class="w-100 d-flex justify-content-between">
                        <p>About Us</p>
                        <mat-icon>sentiment_satisfied_alt</mat-icon>
                    </div>
                </a>

                <!-- <a class="text-font-style" mat-list-item (click)="logout();sidenav.toggle();">
                    <div class="w-100 d-flex justify-content-between">
                        <p>Logout</p>
                        <mat-icon>power_settings_new</mat-icon>
                    </div>
                </a> -->
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content class="matSideNavContent bg-white">
            <mat-progress-bar *ngIf="isLogout" mode="indeterminate"></mat-progress-bar>
            <router-outlet></router-outlet>
            <ng-template #logoutDialog>
                <p matDialogTitle class="mb-2 text-font-style"><small>Are you sure you want to logout?</small></p>
                <mat-dialog-actions align="end">
                    <button class="btn btn-outline-light text-font-style" mat-stroked-button
                        matDialogClose="No">No</button>
                    <button class="btn btn-outline-light text-font-style" mat-stroked-button
                        matDialogClose="Yes">Yes</button>
                </mat-dialog-actions>
            </ng-template>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>


<!-- <mat-toolbar color="primary" class="sticky-top">
    <mat-toolbar-row class="justify-content-end">
        <button class="btn btn-outline-primary" (click)="openSelectedItems();" mat-raised-button>
            Selected Items
            <mat-icon matSuffix [matBadge]="selectedItemsCount" matBadgePosition="after">keyboard_arrow_right</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar> -->

<!-- Dialog box for go back to shop selection page -->
<ng-template #goBackDialog>
    <p matDialogTitle class="mb-2 text-font-style"><small>Go to shop selection page?</small></p>
    <mat-dialog-actions align="end">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
    </mat-dialog-actions>
</ng-template>