<mat-progress-bar *ngIf="isLogout" mode="indeterminate"></mat-progress-bar>

<!-- sm -->
<div [ngSwitch]="profilePageSelector" class="d-block d-sm-block d-md-none d-lg-none d-xl-none h-100">
    <div *ngSwitchCase="'home'">
        <div class="container-fluid mt-2">
            <mat-card class="p-0 user-profile-card">
                <mat-card-header class="justify-content-center">
                    <img mat-card-image class="coverImage m-0" src="./assets/images/coronavirus-mask.png">
                </mat-card-header>
                <div class="userProfileImage">
                    <!-- <img mat-card-image class="profileImage rounded-circle shadow"
                    src="https://www.dhinasiangadi.com/Images/redlabel.jpg" alt="Photo of a Shiba Inu"> -->
                    <img mat-card-image class="profileImage rounded-circle shadow" *ngIf="isProfileImageEmpty == ''"
                        style="outline: 0;" draggable="false" src="./assets/images/user_image.png">
                    <img mat-card-image class="profileImage rounded-circle shadow" *ngIf="isProfileImageEmpty != ''"
                        style="outline: 0;" draggable="false" [src]="isProfileImageEmpty">
                </div>
                <mat-card-content class="m-0">
                    <div class="d-flex justify-content-between" style="position: relative;">
                        <span></span>
                        <div class="d-inline text-right mr-3">
                            <h4 class="m-0 text-font-style">{{userName}}</h4>
                            <h4 class="m-0 text-font-style">{{email}}</h4>
                            <h4 class="m-0 text-font-style"><small>{{mobileNumber}}</small></h4>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="mt-3 container-fluid">
            <button mat-raised-button class="mb-2 w-100 btn btn-outline-light"
                *ngFor="let cardDetail of cards; index as i"
                (click)="changePageSelector(cardDetail.text, cardDetail.url)">
                <mat-card-title class="text-center text-font-style" style="font-size: 16px;">
                    {{cardDetail.text}}
                    <mat-icon class="float-right mt-2" matSuffix>keyboard_arrow_right</mat-icon>
                </mat-card-title>
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'Edit profile'">
        <router-outlet></router-outlet>
    </div>
    <div *ngSwitchCase="'Change password'">
        <router-outlet></router-outlet>
    </div>
    <div *ngSwitchCase="'Address Manager'">
        <router-outlet></router-outlet>
    </div>
    <div *ngSwitchCase="'Privacy Policy'">
        <router-outlet></router-outlet>
    </div>
    <div *ngSwitchCase="'Terms of use'">
        <router-outlet></router-outlet>
    </div>
    <div *ngSwitchCase="'Help'">
        <router-outlet></router-outlet>
    </div>
    <div *ngSwitchCase="'About'">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- mg, lg, xl -->
<div [ngSwitch]="profilePageSelector" class="d-none d-md-block d-lg-block d-xl-block p-2 h-100">
    <div class="container-fluid text-font-style">
        <div class="container" *ngSwitchCase="'home'">
            <div class="container-fluid mt-2">
                <mat-card class="p-0 user-profile-card">
                    <mat-card-header class="justify-content-center">
                        <img mat-card-image class="coverImage m-0" src="./assets/images/coronavirus-mask.png">
                    </mat-card-header>
                    <div class="userProfileImage">
                        <!-- <img mat-card-image class="profileImage rounded-circle shadow"
                        src="https://www.dhinasiangadi.com/Images/redlabel.jpg" alt="Photo of a Shiba Inu"> -->
                        <img mat-card-image class="profileImage rounded-circle shadow" *ngIf="isProfileImageEmpty == ''"
                            style="outline: 0;" draggable="false" src="./assets/images/user_image.png">
                        <img mat-card-image class="profileImage rounded-circle shadow" *ngIf="isProfileImageEmpty != ''"
                            style="outline: 0;" draggable="false" [src]="isProfileImageEmpty">
                    </div>
                    <mat-card-content class="m-0">
                        <div class="d-flex justify-content-between" style="position: relative;">
                            <span></span>
                            <div class="d-inline text-right mr-3">
                                <h4 class="m-0 text-font-style">{{userName}}</h4>
                                <h4 class="m-0 text-font-style">{{email}}</h4>
                                <h4 class="m-0 text-font-style"><small>{{mobileNumber}}</small></h4>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="mt-3 container-fluid">
                <mat-grid-list cols="2" rowHeight="55px">
                    <mat-grid-tile *ngFor="let cardDetail of cards; index as i">
                        <button mat-raised-button class="m-1 w-100 btn btn-outline-light"
                            (click)="changePageSelector(cardDetail.text, cardDetail.url)">
                            <mat-card-title class="text-center text-font-style" style="font-size: 16px;">
                                {{cardDetail.text}}
                                <mat-icon class="float-right mt-2" matSuffix>keyboard_arrow_right</mat-icon>
                            </mat-card-title>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
        <div class="container" *ngSwitchCase="'Edit profile'">
            <router-outlet></router-outlet>
        </div>
        <div class="container" *ngSwitchCase="'Change password'">
            <router-outlet></router-outlet>
        </div>
        <div class="container" *ngSwitchCase="'Address Manager'">
            <router-outlet></router-outlet>
        </div>
        <div class="container" *ngSwitchCase="'Privacy Policy'">
            <router-outlet></router-outlet>
        </div>
        <div class="container" *ngSwitchCase="'Terms of use'">
            <router-outlet></router-outlet>
        </div>
        <div class="container" *ngSwitchCase="'Help'">
            <router-outlet></router-outlet>
        </div>
        <div class="container" *ngSwitchCase="'About'">
            <router-outlet></router-outlet>
        </div>
    </div>
    <ng-template #logoutDialog>
        <p matDialogTitle class="mb-2 text-font-style"><small>Are you sure you want to logout?</small></p>
        <mat-dialog-actions align="end">
            <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
            <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
        </mat-dialog-actions>
    </ng-template>