<mat-progress-bar *ngIf="!isLoggedIn" mode="query"></mat-progress-bar>

<div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
    <mat-toolbar *ngIf="isLoggedIn" color="primary" class="sticky-top">
        <mat-toolbar-row class="text-font-style" color="primary">
            <button mat-icon-button style="outline: 0;" (click)="goBack()">
                <mat-icon matSuffix>arrow_back</mat-icon>
            </button>
            <p routerLink="/homePage/shopDetailForm" class="text-font-style mt-2 mb-0 ml-2" style="outline: 0; border: 0;">Dhinasi Angadi</p>
            <!-- <img src="./assets/icons/Dhinasi_Angadi_Admin_48x48.png" alt=""> -->
            <!-- <img src="./assets/icons/Dhinasi_Angadi_72x72.jpg" style="height: 54px; width: 75px; border-radius: 13px;"> -->
            <span style="flex: 1 1 auto;"></span>
            <button mat-button class="btn shadow-none text-white text-font-style" (click)="aboutUsBtn()">About
                Us</button>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-tab-group *ngIf="isLoggedIn" [selectedIndex]="selectedTab" mat-stretch-tabs>
        <mat-tab>
            <ng-template matTabLabel>
                <span class="text-font-style">Login</span>
            </ng-template>
            <ng-template matTabContent>
                <div *ngIf="!loginResponse" class="container h-100" (swipeleft)="swipe($event)"
                    (swiperight)="swipe($event)">
                    <div class="row justify-content-center align-items-center h-100">
                        <form [formGroup]="signin" autocomplete="off"
                            class="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-5">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Mobile Number
                                    </mat-label>
                                    <input formControlName="phoneNumber" type="tel" matInput placeholder="999-888-9999">
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signin.controls['phoneNumber'].invalid">
                                        Please enter valid mobile number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Password</mat-label>
                                    <input formControlName="password" matInput [type]="hide ? 'password' : 'text'">
                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signin.controls['password'].invalid">
                                        Password is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <p class="text-center"><a class="text-font-style" routerLink="/"
                                    (click)="resetPassword()">Forgot password?</a></p>

                            <div class="col-12 text-center mt-2">
                                <button type="submit" class="text-font-style col-6 btn btn-outline-light"
                                    mat-raised-button (click)="signin.valid ? onLoginSubmit(): null">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div *ngIf="loginResponse" class="container h-100">
                    <div class="row justify-content-center align-items-center h-100">
                        <mat-progress-spinner color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <span class="text-font-style">Signup</span>
            </ng-template>
            <ng-template matTabContent>
                <div *ngIf="!signupResponse" class="container h-100" (swipeleft)="swipe($event)"
                    (swiperight)="swipe($event)">
                    <div class="row justify-content-center align-items-center h-100">
                        <form [formGroup]="signup" autocomplete="off"
                            class="col-10 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Name</mat-label>
                                    <input formControlName="name" type="text" matInput placeholder="Ex. Sanketh" />
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your name</mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['name'].invalid">
                                        Please enter your name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Mobile number
                                    </mat-label>
                                    <span matPrefix>+91 &nbsp;</span>
                                    <input formControlName="mobileNumber" type="tel" matInput
                                        placeholder="Ex. 999-888-9999" />
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mobile number?</mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['mobileNumber'].invalid">
                                        Please enter valid mobile number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Email ID</mat-label>
                                    <input formControlName="email" type="email" matInput
                                        placeholder="Ex. abc@gmail.com" />
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mail id?
                                    </mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['email'].invalid">
                                        Please enter valid email address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Password</mat-label>
                                    <input formControlName="password" matInput [type]="hide1 ? 'password' : 'text'" />
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Give a strong password</mat-hint>
                                    <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                        <mat-icon>{{hide1 ? "visibility_off" : "visibility"}}</mat-icon>
                                    </button>
                                    <mat-error
                                        *ngIf="signup.controls['password'].invalid && (signup.controls['password'].dirty || signup.controls['password'].touched)">
                                        <mat-error *ngIf="signup.controls['password'].errors.required">
                                            Password is required.
                                        </mat-error>
                                        <mat-error *ngIf="signup.controls['password'].errors?.pattern">
                                            Password should have min 8 characters. (A-Z, a-z, 0-9 & one special
                                            character).
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Confirm password
                                    </mat-label>
                                    <input formControlName="confirmpassword" matInput
                                        [type]="hide2 ? 'password' : 'text'" />
                                    <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                        <mat-icon>{{hide2 ? "visibility_off" : "visibility"}}</mat-icon>
                                    </button>
                                    <mat-error
                                        *ngIf="signup.controls['confirmpassword'].invalid && (signup.controls['confirmpassword'].dirty || signup.controls['confirmpassword'].touched)">
                                        <mat-error *ngIf="signup.controls['confirmpassword'].errors.required">
                                            Confirm password is required.
                                        </mat-error>
                                        <mat-error *ngIf="signup.controls['confirmpassword'].errors.mustMatch">
                                            Confirm password does not match.
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 text-center mt-2">
                                <button class="text-font-style col-6 btn btn-outline-light" mat-raised-button
                                    (click)="signup.valid ? onSignupSubmit() : null">
                                    Sign Up
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div *ngIf="signupResponse" class="container h-100">
                    <div class="row justify-content-center align-items-center h-100">
                        <mat-progress-spinner color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                </div>
            </ng-template>

        </mat-tab>
        <div *ngIf="signupResponse" class="container h-100">
            <div class="row justify-content-center align-items-center h-100">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </mat-tab-group>
</div>

<!-- md, lg, xl -->
<div (window:resize)="onResize($event)" class="d-none d-md-block d-lg-block d-xl-block h-100">
    <mat-toolbar *ngIf="isLoggedIn" color="primary" class="sticky-top shadow-sm">
        <mat-toolbar-row class="text-font-style" color="primary">
            <button mat-icon-button style="outline: 0;" (click)="goBack()">
                <mat-icon matSuffix>arrow_back</mat-icon>
            </button>
            <p routerLink="/homePage/shopDetailForm" class="text-font-style mt-2 mb-0 ml-2" style="outline: 0; border: 0;">Dhinasi Angadi</p>
            <!-- <img src="./assets/icons/dhinasi_angadi-72x72.png" alt=""> -->
            <!-- <img src="./assets/icons/Dhinasi_Angadi_72x72.jpg" style="height: 54px; width: 75px; border-radius: 13px;"> -->


            <span style="flex: 1 1 auto;"></span>
            <button mat-button class="btn shadow-none text-white text-font-style" (click)="aboutUsBtn()">About
                Us</button>
        </mat-toolbar-row>
    </mat-toolbar>


    <div *ngIf="isLoggedIn" class="h-100">
        <div class="d-flex h-100">
            <div class="col-6 p-0 bg-dark">
                <div class="row justify-content-center align-items-center h-100">
                    <div class="d-inline">
                        <p class="text-font-style text-white display-4 m-0">Dhinasi angadi</p>
                        <p class="text-font-style text-white text-right">Your home delivery partner</p>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <mat-tab-group [selectedIndex]="selectedTab" mat-stretch-tabs>
                    <mat-tab>
                        <ng-template matTabLabel>
                            <span class="text-font-style">Login</span>
                        </ng-template>
                        <ng-template matTabContent class="style-6">
                            <div *ngIf="!loginResponse" (swipeleft)="swipe($event)" (swiperight)="swipe($event)"
                                class="h-100">
                                <div class="row justify-content-center align-items-center h-100">
                                    <form [formGroup]="signin" autocomplete="off" class="text-center">
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Mobile Number
                                            </mat-label>
                                            <!-- <span matPrefix>+91 &nbsp;</span> -->
                                            <input formControlName="phoneNumber" type="tel" matInput
                                                placeholder="999-888-9999">
                                            <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                                            <mat-error class="text-font-style" style="margin-top: 1px"
                                                *ngIf="signin.controls['phoneNumber'].invalid">
                                                Please enter valid mobile number
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Password
                                            </mat-label>
                                            <input formControlName="password" matInput
                                                [type]="hide ? 'password' : 'text'">
                                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </button>
                                            <mat-error class="text-font-style" style="margin-top: 1px"
                                                *ngIf="signin.controls['password'].invalid">
                                                Password is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                        <p class="text-center"><a class="text-font-style" routerLink="/"
                                                (click)="resetPassword()">Forgot password?</a>
                                        </p>

                                        <div class="col-12 text-center mt-2">
                                            <button type="submit" class="text-font-style col-6 btn btn-outline-light"
                                                mat-raised-button
                                                (click)="signin.valid ? onLoginSubmit(): null">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div *ngIf="loginResponse" class="container h-100">
                                <div class="row justify-content-center align-items-center h-100">
                                    <mat-progress-spinner color="primary" mode="indeterminate">
                                    </mat-progress-spinner>
                                </div>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template matTabLabel>
                            <span class="text-font-style">Signup</span>
                        </ng-template>
                        <ng-template matTabContent class="style-6">
                            <div *ngIf="!signupResponse" (swipeleft)="swipe($event)" (swiperight)="swipe($event)"
                                class="h-100">
                                <div class="row justify-content-center align-items-center h-100">
                                    <form [formGroup]="signup" autocomplete="off" class="text-center col-12">
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Name</mat-label>
                                            <input formControlName="name" type="text" matInput
                                                placeholder="Ex. Sanketh" />
                                            <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your name</mat-hint>
                                            <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['name'].invalid">
                                                Please enter your name
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Mobile number
                                            </mat-label>
                                            <span matPrefix>+91 &nbsp;</span>
                                            <input autocomplete="off" formControlName="mobileNumber" type="tel" matInput
                                                placeholder="Ex. 999-888-9999" />
                                            <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mobile number?</mat-hint>
                                            <mat-error class="text-font-style" style="margin-top: 1px"
                                                *ngIf="signup.controls['mobileNumber'].invalid">
                                                Please enter valid mobile number
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Email ID
                                            </mat-label>
                                            <input formControlName="email" type="email" matInput
                                                placeholder="Ex. abc@gmail.com" />
                                            <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mail id?
                                            </mat-hint>
                                            <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['email'].invalid">
                                                Please enter valid email address
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Password
                                            </mat-label>
                                            <input formControlName="password" matInput
                                                [type]="hide1 ? 'password' : 'text'" />
                                            <mat-hint class="text-font-style" sytle="margin-top: 1px">Give a strong password</mat-hint>
                                            <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                                <mat-icon>{{hide1 ? "visibility_off" : "visibility"}}</mat-icon>
                                            </button>
                                            <!-- <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['password'].errors.required">
                                            Password is <strong>required. (min length 8)</strong>
                                        </mat-error> -->
                                            <mat-error
                                                *ngIf="signup.controls['password'].invalid && (signup.controls['password'].dirty || signup.controls['password'].touched)">
                                                <mat-error *ngIf="signup.controls['password'].errors.required">
                                                    Password is required.
                                                </mat-error>
                                                <mat-error *ngIf="signup.controls['password'].errors?.pattern">
                                                    Password should have min 8 characters. (A-Z, a-z, 0-9 & one special
                                                    character).
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-75">
                                            <mat-label class="text-font-style" style="font-size: 14px;">Confirm password
                                            </mat-label>
                                            <input formControlName="confirmpassword" matInput
                                                [type]="hide2 ? 'password' : 'text'" />
                                            <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                                <mat-icon>{{hide2 ? "visibility_off" : "visibility"}}</mat-icon>
                                            </button>
                                            <mat-error
                                                *ngIf="signup.controls['confirmpassword'].invalid && (signup.controls['confirmpassword'].dirty || signup.controls['confirmpassword'].touched)">
                                                <mat-error *ngIf="signup.controls['confirmpassword'].errors.required">
                                                    Confirm password is required.
                                                </mat-error>
                                                <mat-error *ngIf="signup.controls['confirmpassword'].errors.mustMatch">
                                                    Confirm password does not match.
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                        <div class="col-12 text-center mt-2">
                                            <button class="text-font-style col-6 btn btn-outline-light"
                                                mat-raised-button (click)="signup.valid ? onSignupSubmit() : null">
                                                Sign Up
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div *ngIf="signupResponse" class="container h-100">
                                <div class="row justify-content-center align-items-center h-100">
                                    <mat-progress-spinner color="primary" mode="indeterminate">
                                    </mat-progress-spinner>
                                </div>
                            </div>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>

<!-- Dialog box for Login and Signup for md, lg, xl screen size-->
<!-- <ng-template #login>
    <div class="d-none d-md-block d-lg-block d-xl-block" mat-dialog-content>
        <mat-tab-group *ngIf="isLoggedIn" [selectedIndex]="selectedTab" mat-stretch-tabs>
            <mat-tab>
                <ng-template matTabLabel>
                    <span class="text-font-style">Login</span>
                </ng-template>
                <ng-template matTabContent class="style-6">
                    <div *ngIf="!loginResponse" (swipeleft)="swipe($event)" (swiperight)="swipe($event)" class="h-100">
                        <div class="row justify-content-center align-items-center h-75">
                            <form [formGroup]="signin" autocomplete="off" class="text-center">
                                <mat-form-field class="w-75">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Mobile Number
                                    </mat-label>
                                    <input formControlName="phoneNumber" type="tel" matInput placeholder="999-888-9999">
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signin.controls['phoneNumber'].invalid">
                                        Please enter valid mobile number
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-75">
                                    <mat-label class="text-font-style" style="font-size: 14px;">Password</mat-label>
                                    <input formControlName="password" matInput [type]="hide ? 'password' : 'text'">
                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signin.controls['password'].invalid">
                                        Password is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <p class="text-center"><a class="text-font-style" routerLink="/"
                                        (click)="resetPassword()">Forgot password?</a>
                                </p>

                                <div class="col-12 text-center mt-2">
                                    <button type="submit" class="text-font-style col-6 btn btn-outline-light"
                                        mat-raised-button (click)="signin.valid ? onLoginSubmit(): null">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="loginResponse" class="container h-100">
                        <div class="row justify-content-center align-items-center h-100">
                            <mat-progress-spinner color="primary" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <span class="text-font-style">Signup</span>
                </ng-template>
                <ng-template matTabContent class="style-6">
                    <div *ngIf="!signupResponse" (swipeleft)="swipe($event)" (swiperight)="swipe($event)"
                        class="row justify-content-center">
                        <form [formGroup]="signup" autocomplete="off" class="text-center col-12">
                            <mat-form-field class="w-75">
                                <mat-label class="text-font-style" style="font-size: 14px;">Name</mat-label>
                                <input formControlName="name" type="text" matInput placeholder="Ex. Sanketh" />
                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your name</mat-hint>
                                <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['name'].invalid">
                                    Please enter your name
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-75">
                                <mat-label class="text-font-style" style="font-size: 14px;">Mobile number
                                </mat-label>
                                <span matPrefix>+91 &nbsp;</span>
                                <input autocomplete="off" formControlName="mobileNumber" type="tel" matInput
                                    placeholder="Ex. 999-888-9999" />
                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mobile number?</mat-hint>
                                <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['mobileNumber'].invalid">
                                    Please enter valid mobile number
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-75">
                                <mat-label class="text-font-style" style="font-size: 14px;">Email ID</mat-label>
                                <input formControlName="email" type="email" matInput
                                    placeholder="Ex. abc@gmail.com" />
                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mail id?
                                </mat-hint>
                                <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="signup.controls['email'].invalid">
                                    Please enter valid email address
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-75">
                                <mat-label class="text-font-style" style="font-size: 14px;">Password</mat-label>
                                <input formControlName="password" matInput [type]="hide1 ? 'password' : 'text'" />
                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Give a strong password</mat-hint>
                                <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? "visibility_off" : "visibility"}}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="signup.controls['password'].invalid && (signup.controls['password'].dirty || signup.controls['password'].touched)">
                                    <mat-error *ngIf="signup.controls['password'].errors.required">
                                        Password is required.
                                    </mat-error>
                                    <mat-error *ngIf="signup.controls['password'].errors?.pattern">
                                        Password should have min 8 characters. (A-Z, a-z, 0-9 & one special character).
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-75">
                                <mat-label class="text-font-style" style="font-size: 14px;">Confirm password
                                </mat-label>
                                <input formControlName="confirmpassword" matInput
                                    [type]="hide2 ? 'password' : 'text'" />
                                <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? "visibility_off" : "visibility"}}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="signup.controls['confirmpassword'].invalid && (signup.controls['confirmpassword'].dirty || signup.controls['confirmpassword'].touched)">
                                    <mat-error *ngIf="signup.controls['confirmpassword'].errors.required">
                                        Confirm password is required.
                                    </mat-error>
                                    <mat-error *ngIf="signup.controls['confirmpassword'].errors.mustMatch">
                                        Confirm password does not match.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                            <div class="col-12 text-center mt-2">
                                <button class="text-font-style col-6 btn btn-outline-light" mat-raised-button
                                    (click)="signup.valid ? onSignupSubmit() : null">
                                    Sign Up
                                </button>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="signupResponse" class="container h-100">
                        <div class="row justify-content-center align-items-center h-100">
                            <mat-progress-spinner color="primary" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-template> -->

<ng-template #forgotPasswordDialog>
    <p matDialogTitle class="mb-2 text-font-style" *ngIf="selectedValue == 'reset'">Reset Password?</p>
    <p matDialogTitle class="mb-2 text-font-style" *ngIf="selectedValue == 'finished'">Enter Details</p>
    <mat-dialog-content class="p-3">
        <form [formGroup]="forgotPassword" autocomplete="off" *ngIf="selectedValue == 'reset'">
            <div class="row justify-content-center align-content-center">
                <!-- <mat-form-field class="w-75">
                    <mat-label class="text-font-style" style="font-size: 14px;">Mobile number
                    </mat-label>
                    <span matPrefix>+91 &nbsp;</span>
                    <input formControlName="phoneNumber" type="tel" matInput placeholder="Ex. 999-888-9999" />
                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, enter your registered mobile number</mat-hint>
                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="forgotPassword.controls['phoneNumber'].invalid">
                        Please enter valid mobile number
                    </mat-error>
                </mat-form-field> -->
                <mat-form-field class="w-75">
                    <mat-label class="text-font-style" style="font-size: 14px;">Email ID</mat-label>
                    <input formControlName="email" type="email" matInput placeholder="Ex. abc@gmail.com" />
                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, enter your registered email id
                    </mat-hint>
                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="forgotPassword.controls['email'].invalid">
                        Please enter valid email address
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="selectedValue == 'reset'" class="col-12 text-right mt-3">
                <button class="btn btn-outline-light text-font-style mr-2" mat-stroked-button
                    matDialogClose="No">No</button>
                <button class="btn btn-outline-light text-font-style"
                    (click)="forgotPassword.valid ? confirmReset() : null" mat-stroked-button>Reset</button>
            </div>
        </form>
        <div *ngIf="selectedValue == 'load'" class="row justify-content-center">
            <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
        <div class="row justify-content-center" *ngIf="selectedValue == 'finished'">
            <form [formGroup]="resetNewPassword" autocomplete="off" class="text-center col-12">
                <mat-form-field class="w-75">
                    <mat-label class="text-font-style" style="font-size: 14px;">Password</mat-label>
                    <input formControlName="temporaryPassword" type="text" matInput placeholder="Received password" />
                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, type the password you have received</mat-hint>
                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="resetNewPassword.controls['temporaryPassword'].invalid">
                        Please enter your name
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-75">
                    <mat-label class="text-font-style" style="font-size: 14px;">New Password</mat-label>
                    <input formControlName="newPassword" matInput [type]="hide3 ? 'password' : 'text'" />
                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Give a strong password</mat-hint>
                    <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide3">
                        <mat-icon>{{hide3 ? "visibility_off" : "visibility"}}</mat-icon>
                    </button>
                    <mat-error
                        *ngIf="resetNewPassword.controls['newPassword'].invalid && (resetNewPassword.controls['newPassword'].dirty || resetNewPassword.controls['newPassword'].touched)">
                        <mat-error *ngIf="resetNewPassword.controls['newPassword'].errors.required">
                            Password is required.
                        </mat-error>
                        <mat-error *ngIf="resetNewPassword.controls['newPassword'].errors?.pattern">
                            Password should have min 8 characters. (A-Z, a-z, 0-9 & one special character).
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-75">
                    <mat-label class="text-font-style" style="font-size: 14px;">Confirm password
                    </mat-label>
                    <input formControlName="confirmNewPassword" matInput [type]="hide4 ? 'password' : 'text'" />
                    <button mat-icon-button matSuffix (click)="hide4 = !hide4" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide4">
                        <mat-icon>{{hide4 ? "visibility_off" : "visibility"}}</mat-icon>
                    </button>
                    <mat-error
                        *ngIf="resetNewPassword.controls['confirmNewPassword'].invalid && (resetNewPassword.controls['confirmNewPassword'].dirty || resetNewPassword.controls['confirmNewPassword'].touched)">
                        <mat-error *ngIf="resetNewPassword.controls['confirmNewPassword'].errors.required">
                            Confirm password is required.
                        </mat-error>
                        <mat-error *ngIf="resetNewPassword.controls['confirmNewPassword'].errors.mustMatch">
                            Confirm password does not match.
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <div class="col-12 text-center mt-2">
                    <button class="text-font-style col-6 btn btn-outline-light" mat-raised-button
                        (click)="resetNewPassword.valid ? onResetNewPasswordConfirm() : null">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </mat-dialog-content>
    <!-- <mat-dialog-actions align="end" *ngIf="selectedValue == 'reset'">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
        <button class="btn btn-outline-light text-font-style" (click)="forgotPassword.valid ? confirmReset() : null"
            mat-stroked-button>Reset</button>
    </mat-dialog-actions> -->
    <!-- <mat-dialog-actions align="end" *ngIf="selectedValue == 'finished'">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Ok">Ok</button>
    </mat-dialog-actions> -->
</ng-template>

<ng-template #aboutUsDialog>
    <!-- <p matDialogTitle class="mb-2 text-font-style">About Us</p> -->
    <mat-dialog-content class="p-0">
        <app-about></app-about>
    </mat-dialog-content>
</ng-template>