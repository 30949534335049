import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"],
})
export class AboutComponent implements OnInit {
  isLoginPage: boolean;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.isLoginPage = true;
    // if (this.router.url != "/") {
    //   this.isLoginPage = false;
    // } else {
    //   this.isLoginPage = true;
    // }
  }
}
