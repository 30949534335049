import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { throwError, BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoginSignupService {
  APIURL = environment.APIURL;

  constructor(public http: HttpClient, public router: Router) {}

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
      // 400, 401, 403, 404, 405, 409
      if (error.status == 400) {
      } else if (error.status == 401) {
      } else if (error.status == 403) {
      } else if (error.status == 404) {
      } else if (error.status == 405) {
      } else if (error.status == 409) {
      }
      // Generic way to display the messag and status in console window
      window.alert(errorMessage);
      return throwError(errorMessage);
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      // 500, 503
      if (error.status == 500) {
        // Generic way to display the messag and status in console window
        window.alert(errorMessage);
        return throwError(errorMessage);
      } else if (error.status == 503) {
        // Generic way to display the messag and status in console window
        window.alert(errorMessage);
        return throwError(errorMessage);
      }
    }
  }

  login(item) {
    var customerLoginURL = this.APIURL + "customer_login.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = item;
    // console.log("Service called, URL : " + customerLoginURL);
    return this.http
      .post(customerLoginURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  signup(details) {
    var customerLoginURL = this.APIURL + "customer_signup.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = details;
    // console.log("Service called, URL : " + customerLoginURL);
    return this.http
      .post(customerLoginURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  logout(data) {
    var customerLogoutURL = this.APIURL + "customer_logout.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = data;
    // console.log("Service called, URL : " + customerLogoutURL);
    return this.http
      .post(customerLogoutURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  isLoggedIn(Customer_sessionData) {
    var isCustomerLoggedInURL = this.APIURL + "isCustomerLoggedIn.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = Customer_sessionData;
    // console.log("Service called, URL : " + customerLogoutURL);
    return this.http
      .post(isCustomerLoggedInURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  resetPassword(email) {
    var resetPasswordURL = this.APIURL + "customer_reset_password.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = email;
    return this.http
      .post(resetPasswordURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  confirmReset(email) {
    var resetPasswordURL = this.APIURL + "customer_update_password.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = email;
    return this.http
      .post(resetPasswordURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }
}
