import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MessagingService } from "src/app/service/messaging.service";
import { LoginSignupService } from "src/app/service/login-signup-service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  isProfileImageEmpty: any;
  userName: string;
  email: string;
  mobileNumber: string;
  isLogout: boolean;
  @ViewChild("logoutDialog", { static: true })
  logoutDialog: TemplateRef<any>;

  cards: any = [
    { text: "Edit profile", url: "/homePage/userProfile/profileEdit" },
    { text: "Change password", url: "/homePage/userProfile/changePassword" },
    // { text: "Address Manager", url: "/homePage/userProfile/addressManager" },
    { text: "Privacy Policy", url: "/homePage/userProfile/privacyPolicy" },
    { text: "Terms of use", url: "/homePage/userProfile/termsofuse" },
    // { text: "Help", url: "/homePage/userProfile/help" },
    // { text: "About", url: "/homePage/userProfile/about" },
    { text: "Logout", url: "" },
  ];
  profilePageSelector: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public messagingService: MessagingService,
    public service: LoginSignupService
  ) {}

  ngOnInit() {
    this.profilePageSelector = "home";
    this.isProfileImageEmpty =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))[
            "customer_profileimage"
          ];
    this.userName =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))[
            "customer_name"
          ];
    this.email =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))["email"];
    this.mobileNumber =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))["username"];
  }

  changePageSelector(ev, url) {
    // console.log(ev);
    this.profilePageSelector = ev;
    if (url === "") {
      this.profilePageSelector = "home";
      this.logout();
    } else {
      this.router.navigate([url]);
    }
  }
  revertPageSelector(ev) {
    this.profilePageSelector = ev;
    this.router.navigate(["/homePage/userProfile/"]);
  }
  logout() {
    const dialogRef = this.dialog.open(this.logoutDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.isLogout = true;
          let data = JSON.parse(localStorage.getItem("Customer_sessionData"));
          this.messagingService.deviceToken.subscribe((token) => {
            data.deviceToken = token === null ? data.username + "_noDeviceIdGenerated" : token;;
          });
          this.service.logout(data).subscribe((result) => {
            if (result == "Logged out successfully") {
              this.isLogout = false;
              // localStorage.clear();
              localStorage.removeItem("shopId");
              localStorage.removeItem("source");
              localStorage.removeItem("Customer_sessionData");
              localStorage.removeItem("HomePageErrorCode");
              localStorage.removeItem("LoginPageErrorCode");
              localStorage.removeItem("cartItemList");
              this.router.navigate(["/"]);
            } else {
              console.log("not loged out");
            }
          });
        } else {
        }
      }
    });
  }
}
