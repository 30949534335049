import { Injectable, NgZone } from "@angular/core";
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { throwError, BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HomePageService {
  APIURL = environment.APIURL;

  public shopDetails = new BehaviorSubject([]);
  shopDetail = this.shopDetails.asObservable();

  public groceryDetails = new BehaviorSubject([]);
  groceryDetail = this.groceryDetails.asObservable();

  public cartItemsList = new BehaviorSubject([]);
  currentItems = this.cartItemsList.asObservable();


  finalItemCartListValue(item: any) {
    this.cartItemsList.next(item);
  }

  constructor(public http: HttpClient, public router: Router, private zone: NgZone) {}

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    // Check the internet Connection

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
      // 400, 401, 403, 404, 405, 409
      if (error.status == 400) {
        
      } else if (error.status == 401) {
        
      } else if (error.status == 403) {
        
      } else if (error.status == 404) {
        
      } else if (error.status == 405) {
        
      } else if (error.status == 409) {
        
      } else {
        
      }
      // Generic way to display the messag and status in console window
      window.alert(errorMessage);
      return throwError(errorMessage);
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      // 500, 503
      if (error.status == 500) {
        
        // Generic way to display the messag and status in console window
        window.alert(errorMessage);
        return throwError(errorMessage);
      } else if (error.status == 503) {
        
        // Generic way to display the messag and status in console window
        window.alert(errorMessage);
        return throwError(errorMessage);
      } else {
        
      }
    }
  }

  storeShopDetails(list) {
    this.shopDetails.next(list);
  }

  storeGroceryDetails(grocery) {
    this.groceryDetails.next(grocery);
    localStorage.setItem("source", grocery);
  }

  getShopList() {
    var dhinsiShopListURL = this.APIURL + "shoplist.php";
    // console.log("Service called, URL : " + dhinsiShopListURL);
    return this.http.get(dhinsiShopListURL).pipe(catchError(this.handleError));
  }
  getItemList(data: any) {
    var shopItemListURL = this.APIURL + "shop_itemlist_customer.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = data;
    // console.log("Service called, URL : " + shopItemListURL);
    return this.http
      .post(shopItemListURL, body, { headers, responseType: "json" })
      .pipe(catchError(this.handleError));
  }

  getDeliveryList() {
    var deliveryAreaListURL = this.APIURL + "deliveryAreaList.php";
    // console.log("Service called, URL : " + deliveryAreaListURL);
    return this.http
      .get(deliveryAreaListURL)
      .pipe(catchError(this.handleError));
  }

  deliveryCharge(data) {
    var deliveryChargeURL = this.APIURL + "deliveryCharge.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = data;
    // console.log("Service called, URL : " + deliveryChargeURL);
    return this.http
      .post(deliveryChargeURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  getOrderHistory(customerID): Observable<any> {
    var deliveryAreaListURL =
      this.APIURL + "customer_order_history.php?customerID=" + customerID;
    // console.log("Service called, URL : " + deliveryAreaListURL);
    return this.http
      .get(deliveryAreaListURL)
      .pipe(catchError(this.handleError));
  }

  payment(data) {
    var paymentURL = this.APIURL + "order_save.php";
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let body = data;
    // console.log("Service called, URL : " + deliveryChargeURL);
    return this.http
      .post(paymentURL, body, {
        headers,
        responseType: "text" as "json",
      })
      .pipe(catchError(this.handleError));
  }
}
