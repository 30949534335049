import { NgModule } from "@angular/core";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { ShopitemsComponent } from "./shopitems/shopitems.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "../material/material.module";
import { CommonModule } from '@angular/common';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { HomePageRoutingModule } from './home-page-routing.module';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PaymentOptionDetailsComponent } from './payment-option-details/payment-option-details.component';
import { ShopDetailsFormComponent } from './shop-details-form/shop-details-form.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FaqComponent } from './faq/faq.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserProfileModule } from './user-profile/user-profile.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AboutComponent } from "./about/about.component";
import { UploadImageComponent } from './upload-image/upload-image.component';


@NgModule({
  declarations: [
    CheckoutComponent,
    ShopitemsComponent,
    UserProfileComponent,
    OrderHistoryComponent,
    ShopDetailsFormComponent,
    AboutComponent,
    PaymentOptionDetailsComponent,
    FeedbackComponent,
    FaqComponent,
    UploadImageComponent
  ],
  entryComponents: [CheckoutComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    MaterialModule,
    UserProfileModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserProfileModule,
    HomePageRoutingModule,
    ImageCropperModule,
    ScrollingModule
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerGestureConfig,
  }]
})
export class HomePageModule {}
