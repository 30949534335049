<body class="bg-light">
    <mat-progress-bar *ngIf="isDeliveryAmountReceived" mode="indeterminate"></mat-progress-bar>
    <!-- <mat-progress-bar *ngIf="isDeliveryAmountReceived" mode="buffer"></mat-progress-bar> -->
    <mat-toolbar *ngIf="!orderResponse" class="bg-light">
        <mat-toolbar-row>
            <button [disableRipple]="true" class="btn btn-outline-light bg-light" routerLink="/homePage/shopItemList"
                mat-icon-button>
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span style="flex: 1 1 auto;"></span>
            <button color="primary" class="btn btn-outline-light mr-2" (click)="openBottomSheet();" mat-mini-fab>
                <mat-icon>visibility</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <!-- <mat-progress-bar *ngIf="!isItemsOrdered" mode="query"></mat-progress-bar> -->
    <div *ngIf="orderResponse" class="container h-100">
        <div class="row justify-content-center align-items-center h-100">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>
    </div>

    <div *ngIf="!orderResponse" class="d-block d-sm-block d-md-none d-lg-none d-xl-none p-2 w-100">
        <form [formGroup]="paymentAndOrderDetails" class="mb-5" autocomplete="off">
            <div class="container-fluid p-0">
                <mat-card class="mb-2">
                    <div class="col-12">
                        <div class="row mb-2">
                            <div class="col-6 text-font-style">Cart amount</div>
                            <div class="col-6 text-right text-font-style">{{getCartAmount() | currency:'INR'}} </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-6">GST
                                <mat-icon>info</mat-icon>
                            </div>
                            <div class="col-6 text-right">{{getGST() | currency:'INR'}}</div>
                        </div> -->
                        <div class="row mb-2">
                            <div class="col-6 text-font-style">Delivery charge
                                <!-- <mat-icon>info</mat-icon> -->
                            </div>
                            <div *ngIf="deliveryChargeAmount != 0" class="col-6 text-font-style text-right">
                                {{deliveryChargeAmount | currency: 'INR'}}</div>
                            <div *ngIf="deliveryChargeAmount == 0" class="col-6 text-font-style text-right">--</div>
                        </div>
                        <!-- <p *ngIf="deliveryChargeAmount == 0"><small><strong>*Select the delivery location & get the
                                    updated delivery charge.</strong></small></p> -->
                        <hr>
                        <div class="row">
                            <div class="col-6 text-font-style">Total</div>
                            <div class="col-6 text-font-style text-right">{{getTotalAmount() | currency: 'INR'}}</div>
                        </div>
                        <mat-error class="text-font-style text-dark" *ngIf="isDeliveryExtraChargable">
                            <small><strong>Additional {{25 | currency: 'INR'}} will be charged if the cart amount is
                                    less than
                                    {{500|currency: 'INR'}}</strong></small>
                        </mat-error>
                    </div>
                    <hr>
                    <div>
                        <!-- <div class="text-right"><mat-icon>edit</mat-icon></div> -->
                        <mat-form-field class="w-100 mb-2" appearance="outline">
                            <mat-label class="text-font-style">Delivery location</mat-label>
                            <mat-select class="text-font-style" formControlName="address"
                                (selectionChange)="onAreaChange();">
                                <mat-option class="text-font-style" [value]="data"
                                    *ngFor="let data of areaList; index as i">{{data}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix>place</mat-icon>
                            <mat-hint class="text-font-style" sytle="margin-top: 1px" align="start">Tell us which area
                                to deliver</mat-hint>
                            <mat-error class="text-font-style" style="margin-top: 1px"
                                *ngIf="paymentAndOrderDetails.controls.address.invalid">
                                Please select delivery
                                area</mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100 mb-2" appearance="outline">
                            <mat-label class="text-font-style">Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker1" [min]="minDate" [max]="maxDate"
                                formControlName="deliveryDate" (ngModelChange)="onTimeSlotChange();">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker1 disabled="false"></mat-datepicker>
                            <mat-hint class="text-font-style" sytle="margin-top: 1px">When do you want us to deliver?
                            </mat-hint>
                            <mat-error class="text-font-style" style="margin-top: 1px"
                                *ngIf="paymentAndOrderDetails.controls.deliveryDate.invalid">Please choose a
                                delivery date</mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-100 mb-2" appearance="outline">
                            <mat-label class="text-font-style">Select available time-slot</mat-label>
                            <mat-select class="text-font-style" formControlName="deliverTime">
                                <mat-option class="text-font-style" *ngFor="let option of timeSlotOptions; index as i"
                                    [value]="option">{{option}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix>av_timer</mat-icon>
                            <mat-hint class="text-font-style" sytle="margin-top: 1px" align="start">Choose a time slot
                                for the delivery
                            </mat-hint>
                            <mat-error class="text-font-style" style="margin-top: 1px"
                                *ngIf="paymentAndOrderDetails.controls.deliverTime.invalid">Please select available
                                delivery time-slot</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field class="w-100 mb-2" appearance="outline">
                            <mat-label>Delivery Time</mat-label>
                            <input matInput placeholder="Ex. 9 AM">
                        </mat-form-field> -->
                        <mat-form-field class="w-100 mb-2" appearance="outline">
                            <mat-label class="text-font-style">Address details</mat-label>
                            <textarea class="text-font-style" matInput placeholder="Tell us where to deliver"
                                formControlName="detailedAddress"></textarea>
                            <mat-hint class="text-font-style" sytle="margin-top: 1px">Let us know your delivery address.
                            </mat-hint>
                            <mat-error class="text-font-style" style="margin-top: 1px"
                                *ngIf="paymentAndOrderDetails.controls.detailedAddress.invalid">Please fill
                                the delivery address.</mat-error>
                        </mat-form-field>
                        <div class="d-inline">
                            <mat-checkbox *ngIf="isTodayDelivery"
                                [disabled]="!paymentAndOrderDetails.controls['address'].valid"
                                (change)="quickDelivery($event.checked)" [checked]="isQuickDelivery"
                                style="margin: 0 10px;" color="primary" class="text-font-style">
                                Quick Delivery
                            </mat-checkbox>
                            <mat-checkbox (change)="saveAddress == true ? (saveAddress = false): (saveAddress = true)"
                                [checked]="saveAddress" style="margin: 0 10px;" color="primary" class="text-font-style">
                                Save Address
                            </mat-checkbox>
                            <mat-error class="text-font-style text-dark" style="margin-top: 1px"
                                *ngIf="isQuickDelivery"><small><strong>Additional
                                        {{extraDeliveryAmount | currency: 'INR'}} will be charged if you opt this
                                        service.</strong></small>
                            </mat-error>
                        </div>
                    </div>
                    <!-- <mat-checkbox class="text-font-style" color="primary"><small>Save Address</small></mat-checkbox> -->
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="container-fluid mt-2">
                <div class="row">
                    <label class="text-font-style">Payment Options</label>
                    <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="isPaymentSelected">Please
                        select atleast one payment method.</mat-error>
                    <div class="col-12 p-0">
                        <mat-radio-group formControlName="paymentType">
                            <mat-accordion>
                                <mat-expansion-panel class="p-2 mb-1" (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        <div class="d-inline">
                                            <mat-panel-title class="text-font-style">
                                                Cash on delivery
                                            </mat-panel-title>
                                            <mat-panel-description class="text-font-style">
                                                Pay money on delivery.
                                            </mat-panel-description>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="d-flex overflow-auto style-2 p-1">
                                        <!-- col-md-12 col-lg-6 col-xl-6 mr-md-1 mr-lg-1 mr-xl-1 ml-md-1 ml-lg-1 ml-xl-1 -->
                                        <mat-card class="m-2 upi-card">
                                            <mat-card-content class="d-flex justify-content-between">
                                                <img src="https://www.dhinasiangadi.com/Images/cod.png"
                                                    class="upi-card-image">
                                                <mat-radio-button
                                                    [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'COD'"
                                                    (change)="isPaymentSelected = false" class="ml-1 upi-radio-button"
                                                    color="primary" value="COD">
                                                </mat-radio-button>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card *ngIf="eligible" class="m-2 upi-card">
                                            <mat-card-content class="d-flex justify-content-between">
                                                <img src="https://www.dhinasiangadi.com/Images/note.png"
                                                    class="upi-card-image">
                                                <mat-radio-button
                                                    [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'NCOD'"
                                                    (change)="isPaymentSelected = false" class="ml-1 upi-radio-button"
                                                    color="primary" value="NCOD">
                                                </mat-radio-button>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>

                                    <!-- Cash on delivery options fields -->
                                    <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'COD'"
                                        class="p-2 d-flex">
                                        <div class="col-12 text-center">
                                            <mat-error class="text-font-style mb-2 text-dark text-left">
                                                <p *ngIf="paymentAndOrderDetails.controls['address'].valid">
                                                    You need to pay
                                                    <strong>{{getTotalAmount() | currency:'INR'}}</strong>. Please keep
                                                    the change for smooth delivery process.
                                                </p>
                                            </mat-error>
                                            <button class="btn btn-outline-warning text-font-style bg-warning w-75"
                                                (click)="paymentAndOrderDetails.valid ? normalPayment(): null"
                                                mat-raised-button>
                                                Order & Pay on delivery
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'NCOD'"
                                        class="p-2 d-flex">
                                        <div class="col-12 text-center">
                                            <mat-error class="text-font-style mb-2 text-dark text-left">
                                                <p *ngIf="paymentAndOrderDetails.controls['address'].valid">
                                                    1) We
                                                    will
                                                    only collect the delivery charge for this service. Only pay
                                                    <strong>{{deliveryChargeAmount | currency: 'INR'}}</strong> as
                                                    delivery
                                                    charge.
                                                </p>
                                                <p *ngIf="paymentAndOrderDetails.controls['address'].valid">2)
                                                    You need to pay
                                                    <strong>{{getCartAmount() | currency:'INR'}}</strong> to
                                                    shopkeeper.
                                                </p>
                                            </mat-error>
                                            <button class="btn btn-outline-warning text-font-style bg-warning w-75"
                                                (click)="paymentAndOrderDetails.valid ? normalPayment(): null"
                                                mat-raised-button>
                                                Order & Pay later
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>

                                <!-- <mat-expansion-panel class="p-2" (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        <div class="d-inline">
                                            <mat-panel-title class="text-font-style">
                                                UPI
                                            </mat-panel-title>
                                            <mat-panel-description class="text-font-style">
                                                Pay through UPI applications.
                                            </mat-panel-description>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="d-flex overflow-auto style-2 p-1">
                                        <mat-card class="m-2 upi-card">
                                            <mat-card-content class="d-flex justify-content-between">
                                                <img src="https://www.dhinasiangadi.com/Images/GPAY.png"
                                                    class="upi-card-image">
                                                <mat-radio-button
                                                    [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'GPAY'"
                                                    (change)="isPaymentSelected = false;" class="ml-1 upi-radio-button"
                                                    color="primary" value="GPAY">
                                                </mat-radio-button>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card class="m-2 upi-card">
                                            <mat-card-content class="d-flex justify-content-between">
                                                <img src="https://www.dhinasiangadi.com/Images/phonepe.png"
                                                    class="upi-card-image">
                                                <mat-radio-button
                                                    [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'PHONEPE'"
                                                    (change)="isPaymentSelected = false;" class="ml-1 upi-radio-button"
                                                    color="primary" value="PHONEPE">
                                                </mat-radio-button>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card class="m-2 upi-card">
                                            <mat-card-content class="d-flex justify-content-between">
                                                <img src="https://www.dhinasiangadi.com/Images/paytm.png"
                                                    class="upi-card-image">
                                                <mat-radio-button
                                                    [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'PAYTM'"
                                                    (change)="isPaymentSelected = false;" class="ml-1 upi-radio-button"
                                                    color="primary" value="PAYTM">
                                                </mat-radio-button>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    
                                    <!-- VPA fields -->
                                <!-- <form [formGroup]="VPADetails" autocomplete="off">
                                        <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'GPAY'"
                                            class="p-2 d-flex">
                                            <mat-form-field class="w-75" appearance="fill">
                                                <input formControlName="vpa" type="text" matInput
                                                    placeholder="Ex. 9876543210@okhdfcbank" />
                                                <mat-icon *ngIf="gpayVPA" matSuffix style="color: greenyellow;">
                                                    check_circle_outline</mat-icon>
                                                <mat-icon *ngIf="!gpayVPA" matSuffix style="color: red;">
                                                    highlight_off</mat-icon>
                                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, Type your Gpay VPA</mat-hint>
                                                <mat-error class="text-font-style" style="margin-top: 1px"
                                                    *ngIf="VPADetails.controls.vpa.invalid">
                                                    Please enter valid Gpay VPA
                                                </mat-error>
                                            </mat-form-field>
                                            <div class="ml-2">
                                                <button class="btn btn-outline-light"
                                                    (click)="(VPADetails.valid) ? gpayVPAVerify() : null"
                                                    mat-raised-button>
                                                    Verify & Pay
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PHONEPE'"
                                            class="p-2 d-flex">
                                            <mat-form-field class="w-75" appearance="fill">
                                                <input formControlName="vpa" type="text" matInput
                                                    placeholder="Ex. 9876543210@ybl" />
                                                <mat-icon *ngIf="phonepeVPA" matSuffix style="color: greenyellow;">
                                                    check_circle_outline</mat-icon>
                                                <mat-icon *ngIf="!phonepeVPA" matSuffix style="color: red;">
                                                    highlight_off</mat-icon>
                                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, Type your Phonepe VPA</mat-hint>
                                                <mat-error class="text-font-style" style="margin-top: 1px"
                                                    *ngIf="VPADetails.controls.vpa.invalid">
                                                    Please enter valid Phonepe VPA
                                                </mat-error>
                                            </mat-form-field>
                                            <div class="ml-2">
                                                <button class="btn btn-outline-light"
                                                    (click)="(VPADetails.valid) ? phonepeVPAVerify() : null"
                                                    mat-raised-button>
                                                    Verify & Pay
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PAYTM'"
                                            class="p-2 d-flex">
                                            <mat-form-field class="w-75" appearance="fill">
                                                <input formControlName="vpa" type="text" matInput
                                                    placeholder="Ex. 9876543210@paytm" />
                                                <mat-icon *ngIf="patmVPA" matSuffix style="color: greenyellow;">
                                                    check_circle_outline</mat-icon>
                                                <mat-icon *ngIf="!patmVPA" matSuffix style="color: red;">
                                                    highlight_off</mat-icon>
                                                <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, Type your paytm VPA</mat-hint>
                                                <mat-error class="text-font-style" style="margin-top: 1px"
                                                    *ngIf="VPADetails.controls.vpa.invalid">
                                                    Please enter valid paytm VPA
                                                </mat-error>
                                            </mat-form-field>
                                            <div class="ml-2">
                                                <button class="btn btn-outline-light"
                                                    (click)="(VPADetails.valid) ? patmVPAVerify() : null"
                                                    mat-raised-button>
                                                    Verify & Pay
                                                </button>
                                            </div>
                                        </div>
                                    </form> -->

                                <!--  Payment request web integration -->
                                <!-- <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'GPAY'"
                                        class="p-2 d-flex">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-warning bg-warning w-50"
                                                (click)="(paymentAndOrderDetails.valid) ? googlePayPayment() : null"
                                                mat-raised-button>
                                                PAY
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PHONEPE'"
                                        class="p-2 d-flex">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-warning bg-warning w-50"
                                                (click)="(paymentAndOrderDetails.valid) ? phonepePayment() : null"
                                                mat-raised-button>
                                                PAY
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PAYTM'"
                                        class="p-2 d-flex">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-warning bg-warning w-50"
                                                (click)="(paymentAndOrderDetails.valid) ? paytmPayment() : null"
                                                mat-raised-button>
                                                PAY
                                            </button>
                                        </div>
                                    </div> -->
                                <!-- </mat-expansion-panel> -->
                            </mat-accordion>
                            <!-- <mat-card class="mt-1 mb-1 w-100">
                                <mat-card-header class="justify-content-between">
                                    <mat-card-title>UPI Apps</mat-card-title>
                                    <mat-radio-button (change)="isPaymentSelected = false"  color="primary" value="UPI"></mat-radio-button>
                                </mat-card-header>
                            </mat-card> -->

                            <!-- <div class="container-fluid w-100 p-0 mb-3">
                                <label>More Options</label>
                                <mat-accordion>
                                    <mat-expansion-panel class="p-2 mb-2" (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false">
                                        <mat-expansion-panel-header>
                                            <div class="d-inline">
                                                <mat-panel-title>
                                                    Cards
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    Pay through debit/credit/other cards.
                                                </mat-panel-description>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div class="d-flex overflow-auto style-2 p-1">
                                            <mat-card class="m-2 upi-card">
                                                <mat-card-content class="d-flex justify-content-between">
                                                    <img src="https://www.dhinasiangadi.com/Images/credit-card.png"
                                                        class="upi-card-image">
                                                    <mat-radio-button (change)="isPaymentSelected = false"
                                                        class="ml-1 upi-radio-button" color="primary" value="CARD">
                                                    </mat-radio-button>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div> -->
                        </mat-radio-group>
                    </div>

                </div>
            </div>
        </form>
    </div>


    <!-- md, lg, xl -->
    <div *ngIf="!orderResponse" class="d-none d-md-block d-lg-block d-xl-block p-2 h-100">
        <form [formGroup]="paymentAndOrderDetails" autocomplete="off">
            <!-- <mat-toolbar *ngIf="true" class="bg-light">
                <mat-toolbar-row>
                    <div></div>
                    <span style="flex: 1 1 auto;"></span>
                    <div class="mr-3">
                        <button
                            (click)="paymentAndOrderDetails.valid ? proceedToPay(): (paymentAndOrderDetails.controls['paymentType'].valid == false? isPaymentSelected = true : null )"
                            mat-raised-button color="accent" class="btn btn-outline-warning">Proceed to pay</button>
                    </div>

                </mat-toolbar-row>
            </mat-toolbar> -->
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-6 style-1">
                        <mat-card>
                            <div class="container-fluid mb-3">
                                <!-- <div class="text-right"><mat-icon>edit</mat-icon></div> -->
                                <mat-form-field class="w-100 mb-2" appearance="outline">
                                    <mat-label class="text-font-style">Delivery location</mat-label>
                                    <mat-select class="text-font-style" formControlName="address"
                                        (selectionChange)="onAreaChange();">
                                        <mat-option class="text-font-style" [value]="data"
                                            *ngFor="let data of areaList; index as i">{{data}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>place</mat-icon>
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px" align="start">Tell us
                                        which area to deliver
                                    </mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px"
                                        *ngIf="paymentAndOrderDetails.controls.address.invalid">Please select
                                        delivery area</mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-100 mb-2" appearance="outline">
                                    <mat-label class="text-font-style">Choose a date</mat-label>
                                    <input matInput [matDatepicker]="picker2" [min]="minDate" [max]="maxDate"
                                        formControlName="deliveryDate" (ngModelChange)="onTimeSlotChange();">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px">When do you want us to
                                        deliver?</mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px"
                                        *ngIf="paymentAndOrderDetails.controls.deliveryDate.invalid">Please choose a
                                        delivery date</mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-100 mb-2" appearance="outline">
                                    <mat-label class="text-font-style">Select available time-slot</mat-label>
                                    <mat-select class="text-font-style" formControlName="deliverTime">
                                        <mat-option class="text-font-style"
                                            *ngFor="let option of timeSlotOptions; index as i" [value]="option">
                                            {{option}}</mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>av_timer</mat-icon>
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px" align="start">Choose
                                        available time slot for the
                                        delivery
                                    </mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px"
                                        *ngIf="paymentAndOrderDetails.controls.deliverTime.invalid">Please
                                        select available
                                        delivery time-slot</mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-100 mb-2" appearance="outline">
                                    <mat-label class="text-font-style">Address details</mat-label>
                                    <textarea class="text-font-style" matInput placeholder="Tell us where to deliver"
                                        formControlName="detailedAddress"></textarea>
                                    <mat-hint class="text-font-style" sytle="margin-top: 1px">Let us know your delivery
                                        address.</mat-hint>
                                    <mat-error class="text-font-style" style="margin-top: 1px"
                                        *ngIf="paymentAndOrderDetails.controls.detailedAddress.invalid">Please
                                        fill the delivery address.</mat-error>
                                </mat-form-field>
                                <div class="d-inline">
                                    <mat-checkbox *ngIf="isTodayDelivery"
                                        [disabled]="!paymentAndOrderDetails.controls['address'].valid"
                                        (change)="quickDelivery($event.checked)" [checked]="isQuickDelivery"
                                        style="margin: 0 10px;" color="primary" class="text-font-style">
                                        Quick Delivery
                                    </mat-checkbox>
                                    <mat-checkbox
                                        (change)="saveAddress == true ? (saveAddress = false): (saveAddress = true)"
                                        [checked]="saveAddress" style="margin: 0 10px;" color="primary"
                                        class="text-font-style">
                                        Save Address
                                    </mat-checkbox>
                                    <mat-error class="text-font-style text-dark" style="margin-top: 1px"
                                        *ngIf="isQuickDelivery"><small><strong>Additional
                                                {{extraDeliveryAmount | currency: 'INR'}} will be charged if you opt
                                                this
                                                service.</strong></small>
                                    </mat-error>
                                </div>
                                <!-- <mat-checkbox class="text-font-style">Save Address</mat-checkbox> -->
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-6">
                        <mat-card>
                            <div class="col-12">
                                <div class="row mb-2">
                                    <div class="col-6 text-font-style">Cart amount</div>
                                    <div class="col-6 text-right text-font-style">{{getCartAmount() | currency:'INR'}}
                                    </div>
                                </div>
                                <!-- <div class="row">
                                <div class="col-6">GST
                                    <mat-icon>info</mat-icon>
                                </div>
                                <div class="col-6 text-right">{{getGST() | currency:'INR'}}</div>
                            </div> -->
                                <div class="row mb-2">
                                    <div class="col-6 text-font-style">Delivery charge
                                        <!-- <mat-icon>info</mat-icon> -->
                                    </div>
                                    <div *ngIf="deliveryChargeAmount != 0" class="col-6 text-right text-font-style">
                                        {{deliveryChargeAmount | currency: 'INR'}}</div>
                                    <div *ngIf="deliveryChargeAmount == 0" class="col-6 text-right text-font-style">--
                                    </div>
                                </div>
                                <!-- <p *ngIf="deliveryChargeAmount == 0"><small><strong>*Select the delivery location &
                                            get the updated delivery charge.</strong></small></p> -->
                                <hr>
                                <div class="row">
                                    <div class="col-6 text-font-style">Total</div>
                                    <div class="col-6 text-right text-font-style">{{getTotalAmount() | currency: 'INR'}}
                                    </div>
                                </div>
                            </div>
                            <mat-error class="col-12 text-font-style text-dark" *ngIf="isDeliveryExtraChargable">
                                <small><strong>Additional {{25 | currency: 'INR'}} will be charged if the cart amount is
                                        less than
                                        {{500|currency: 'INR'}}</strong></small>
                            </mat-error>
                        </mat-card>
                        <div class="row mt-3">
                            <label class="text-font-style ml-3">Payment Options</label>

                            <div class="col-12">
                                <mat-radio-group formControlName="paymentType">
                                    <mat-accordion>
                                        <mat-expansion-panel class="p-2 mb-1" (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header>
                                                <div class="d-inline">
                                                    <mat-panel-title class="text-font-style">
                                                        Cash on delivery
                                                    </mat-panel-title>
                                                    <mat-panel-description class="text-font-style">
                                                        Pay money on delivery.
                                                    </mat-panel-description>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="d-flex overflow-auto style-2 p-1">
                                                <!-- col-md-12 col-lg-6 col-xl-6 mr-md-1 mr-lg-1 mr-xl-1 ml-md-1 ml-lg-1 ml-xl-1 -->
                                                <mat-card class="m-2 upi-card">
                                                    <mat-card-content class="d-flex justify-content-between">
                                                        <img src="https://www.dhinasiangadi.com/Images/cod.png"
                                                            class="upi-card-image">
                                                        <mat-radio-button
                                                            [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'COD'"
                                                            (change)="isPaymentSelected = false"
                                                            class="ml-1 upi-radio-button" color="primary" value="COD">
                                                        </mat-radio-button>
                                                    </mat-card-content>
                                                </mat-card>
                                                <mat-card *ngIf="eligible" class="m-2 upi-card">
                                                    <mat-card-content class="d-flex justify-content-between">
                                                        <img src="https://www.dhinasiangadi.com/Images/note.png"
                                                            class="upi-card-image">
                                                        <mat-radio-button
                                                            [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'NCOD'"
                                                            (change)="isPaymentSelected = false"
                                                            class="ml-1 upi-radio-button" color="primary" value="NCOD">
                                                        </mat-radio-button>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                            <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'COD'"
                                                class="p-2 d-flex">
                                                <div class="ml-2">
                                                    <mat-error class="text-font-style mb-2 text-dark text-left">
                                                        <p *ngIf="paymentAndOrderDetails.controls['address'].valid">
                                                            You need to pay
                                                            <strong>{{getTotalAmount() | currency:'INR'}}</strong>.
                                                            Please keep
                                                            the change for smooth delivery process.
                                                        </p>
                                                    </mat-error>
                                                    <button class="btn btn-outline-light text-font-style"
                                                        (click)="paymentAndOrderDetails.valid ? normalPayment(): null"
                                                        mat-raised-button>
                                                        Order & Pay on delivery
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="eligible && paymentAndOrderDetails.controls['paymentType'].value == 'NCOD'"
                                                class="p-2 d-flex">
                                                <div class="ml-2">
                                                    <mat-error class="text-font-style mb-2 text-dark text-left">
                                                        <p *ngIf="paymentAndOrderDetails.controls['address'].valid">
                                                            1) We
                                                            will
                                                            only collect the delivery charge for this service. Only pay
                                                            <strong>{{deliveryChargeAmount | currency: 'INR'}}</strong>
                                                            as
                                                            delivery
                                                            charge.
                                                        </p>
                                                        <p *ngIf="paymentAndOrderDetails.controls['address'].valid">2)
                                                            You need to pay
                                                            <strong>{{getCartAmount() | currency:'INR'}}</strong> to
                                                            shopkeeper.
                                                        </p>
                                                    </mat-error>
                                                    <button class="btn btn-outline-light text-font-style"
                                                        (click)="paymentAndOrderDetails.valid ? normalPayment(): null"
                                                        mat-raised-button>
                                                        Order & Pay later
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>

                                        <!-- <mat-expansion-panel class="p-2" (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header>
                                                <div class="d-inline">
                                                    <mat-panel-title class="text-font-style">
                                                        UPI Apps
                                                    </mat-panel-title>
                                                    <mat-panel-description class="text-font-style">
                                                        Pay through UPI applications.
                                                    </mat-panel-description>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="d-flex overflow-auto style-2 p-1">
                                                <mat-card class="m-2 upi-card">
                                                    <mat-card-content class="d-flex justify-content-between">
                                                        <img src="https://www.dhinasiangadi.com/Images/GPAY.png"
                                                            class="upi-card-image">
                                                        <mat-radio-button
                                                            [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'GPAY'"
                                                            (change)="isPaymentSelected = false;"
                                                            class="ml-1 upi-radio-button" color="primary" value="GPAY">
                                                        </mat-radio-button>
                                                    </mat-card-content>
                                                </mat-card>
                                                <mat-card class="m-2 upi-card">
                                                    <mat-card-content class="d-flex justify-content-between">
                                                        <img src="https://www.dhinasiangadi.com/Images/phonepe.png"
                                                            class="upi-card-image">
                                                        <mat-radio-button
                                                            [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'PHONEPE'"
                                                            (change)="isPaymentSelected = false;"
                                                            class="ml-1 upi-radio-button" color="primary"
                                                            value="PHONEPE"></mat-radio-button>
                                                    </mat-card-content>
                                                </mat-card>
                                                <mat-card class="m-2 upi-card">
                                                    <mat-card-content class="d-flex justify-content-between">
                                                        <img src="https://www.dhinasiangadi.com/Images/paytm.png"
                                                            class="upi-card-image">
                                                        <mat-radio-button (change)="isPaymentSelected = false;"
                                                            [checked]="paymentAndOrderDetails.controls['paymentType'].value == 'PAYTM'"
                                                            class="ml-1 upi-radio-button" color="primary" value="PAYTM">
                                                        </mat-radio-button>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div> -->
                                        <!-- VPA fields -->
                                        <!-- <form [formGroup]="VPADetails" autocomplete="off">
                                                <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'GPAY'"
                                                    class="p-2 d-flex">
                                                    <mat-form-field class="w-75" appearance="fill">
                                                        <input formControlName="vpa" type="text" matInput
                                                            placeholder="Ex. 9876543210@okhdfcbank" />
                                                        <mat-icon *ngIf="gpayVPA" matSuffix style="color: greenyellow;">
                                                            check_circle_outline</mat-icon>
                                                        <mat-icon *ngIf="!gpayVPA" matSuffix style="color: red;">
                                                            highlight_off</mat-icon>
                                                        <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, Type your Gpay VPA
                                                        </mat-hint>
                                                        <mat-error class="text-font-style" style="margin-top: 1px"
                                                            *ngIf="VPADetails.controls.vpa.invalid">
                                                            Please enter valid Gpay VPA
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <div class="ml-2">
                                                        <button class="btn btn-outline-light"
                                                            (click)="(VPADetails.valid) ? gpayVPAVerify() : null"
                                                            mat-raised-button>
                                                            Verify & Pay
                                                        </button>
                                                    </div>
                                                </div>
                                                <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PHONEPE'"
                                                    class="p-2 d-flex">
                                                    <mat-form-field class="w-75" appearance="fill">
                                                        <input formControlName="vpa" type="text" matInput
                                                            placeholder="Ex. 9876543210@ybl" />
                                                        <mat-icon *ngIf="phonepeVPA" matSuffix
                                                            style="color: greenyellow;">
                                                            check_circle_outline</mat-icon>
                                                        <mat-icon *ngIf="!phonepeVPA" matSuffix style="color: red;">
                                                            highlight_off</mat-icon>
                                                        <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, Type your Phonepe VPA
                                                        </mat-hint>
                                                        <mat-error class="text-font-style" style="margin-top: 1px"
                                                            *ngIf="VPADetails.controls.vpa.invalid">
                                                            Please enter valid Phonepe VPA
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <div class="ml-2">
                                                        <button class="btn btn-outline-light"
                                                            (click)="(VPADetails.valid) ? phonepeVPAVerify() : null"
                                                            mat-raised-button>
                                                            Verify & Pay
                                                        </button>
                                                    </div>
                                                </div>
                                                <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PAYTM'"
                                                    class="p-2 d-flex">
                                                    <mat-form-field class="w-75" appearance="fill">
                                                        <input formControlName="vpa" type="text" matInput
                                                            placeholder="Ex. 9876543210@paytm" />
                                                        <mat-icon *ngIf="patmVPA" matSuffix style="color: greenyellow;">
                                                            check_circle_outline</mat-icon>
                                                        <mat-icon *ngIf="!patmVPA" matSuffix style="color: red;">
                                                            highlight_off</mat-icon>
                                                        <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, Type your paytm VPA
                                                        </mat-hint>
                                                        <mat-error class="text-font-style" style="margin-top: 1px"
                                                            *ngIf="VPADetails.controls.vpa.invalid">
                                                            Please enter valid paytm VPA
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <div class="ml-2">
                                                        <button class="btn btn-outline-light"
                                                            (click)="(VPADetails.valid) ? patmVPAVerify() : null"
                                                            mat-raised-button>
                                                            Verify & Pay
                                                        </button>
                                                    </div>
                                                </div>
                                            </form> -->

                                        <!--  Payment request web integration -->
                                        <!-- <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'GPAY'"
                                                class="p-2 d-flex">
                                                <div class="col-6">
                                                    <button class="btn btn-outline-warning bg-warning w-100"
                                                        (click)="(paymentAndOrderDetails.valid) ? googlePayPayment() : null"
                                                        mat-raised-button>
                                                        PAY
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PHONEPE'"
                                                class="p-2 d-flex">
                                                <div class="col-6">
                                                    <button class="btn btn-outline-warning bg-warning w-100"
                                                        (click)="(paymentAndOrderDetails.valid) ? phonepePayment() : null"
                                                        mat-raised-button>
                                                        PAY
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="paymentAndOrderDetails.controls['paymentType'].value == 'PAYTM'"
                                                class="p-2 d-flex">
                                                <div class="col-6">
                                                    <button class="btn btn-outline-warning bg-warning w-100"
                                                        (click)="(paymentAndOrderDetails.valid) ? paytmPayment() : null"
                                                        mat-raised-button>
                                                        PAY
                                                    </button>
                                                </div>
                                            </div> -->
                                        <!-- </mat-expansion-panel> -->
                                    </mat-accordion>
                                    <!-- <mat-card class="mt-1 mb-1 w-100">
                                        <mat-card-header class="justify-content-between">
                                            <mat-card-title>UPI Apps</mat-card-title>
                                            <mat-radio-button (change)="isPaymentSelected = false"  color="primary" value="UPI"></mat-radio-button>
                                        </mat-card-header>
                                    </mat-card> -->
                                    <!-- <div class="container-fluid w-100 p-0 mb-3">
                                        <label>More Options</label>
                                        <mat-accordion>
                                            <mat-expansion-panel class="p-2 mb-2" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                                                <mat-expansion-panel-header>
                                                    <div class="d-inline">
                                                        <mat-panel-title>
                                                            Cards
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                            Pay through debit/credit/other cards.
                                                        </mat-panel-description>
                                                    </div>
                                                </mat-expansion-panel-header>
                                                <div class="d-flex overflow-auto style-2 p-1">
                                                    <mat-card class="m-2 upi-card">
                                                        <mat-card-content class="d-flex justify-content-between">
                                                            <img src="https://www.dhinasiangadi.com/Images/credit-card.png"
                                                                class="upi-card-image">
                                                            <mat-radio-button (change)="isPaymentSelected = false"
                                                                class="ml-1 upi-radio-button" color="primary"
                                                                value="CARD"></mat-radio-button>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div> -->
                                </mat-radio-group>
                                <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="isPaymentSelected">
                                    Please
                                    select atleast one payment method.</mat-error>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>