import { Component, ApplicationRef } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Event as NavigationEvent } from "@angular/router";
import { filter, first } from "rxjs/operators";
import { NavigationStart } from "@angular/router";
import { Router } from "@angular/router";
import { MessagingService } from "./service/messaging.service";
import { Observable, Observer, fromEvent, merge, interval, concat } from "rxjs";
import { map } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

declare var google;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Dhinsi";
  message: any;

  googlePayClient: any;

  constructor(
    public appRef: ApplicationRef,
    public updates: SwUpdate,
    public router: Router,
    public _snackBar: MatSnackBar,
    public messagingService: MessagingService
  ) {
    // To check internet connection
    this.createOnline$().subscribe((isOnline) => {
      if (!isOnline) {
        this._snackBar.open("No Internet!. Please check your internet connection", null, {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom"
        });
      } 
      // else {
      //   this._snackBar.open("You are online.", null, {
      //     duration: 2000
      //   });
      // }
    });

    // To check whether any updates are available in the new build.
    updates.available.subscribe((x) => {
      updates.activateUpdate().then(() => {
        document.location.reload();
      });
    });

    // To detect the back and forward action of the router.
    router.events
      .pipe(
        // The "events" stream contains all the navigation events. For this demo,
        // though, we only care about the NavigationStart event as it contains
        // information about what initiated the navigation sequence.
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: NavigationStart) => {
        // console.group("NavigationStart Event");
        // Every navigation sequence is given a unique ID. Even "popstate"
        // navigations are really just "roll forward" navigations that get
        // a new, unique ID.
        // console.log("navigation id:", event.id);
        // console.log("route:", event.url);
        // The "navigationTrigger" will be one of:
        // --
        // - imperative (ie, user clicked a link).
        // - popstate (ie, browser controlled change such as Back button).
        // - hashchange
        // --
        // NOTE: I am not sure what triggers the "hashchange" type.
        // console.log("trigger:", event.navigationTrigger);

        // This "restoredState" property is defined when the navigation
        // event is triggered by a "popstate" event (ex, back / forward
        // buttons). It will contain the ID of the earlier navigation event
        // to which the browser is returning.
        // --
        // CAUTION: This ID may not be part of the current page rendering.
        // This value is pulled out of the browser; and, may exist across
        // page refreshes.

        // console.log(this.router.url);
        // console.log(event.url);
        if (event.restoredState) {
          // console.log(
          //   "restoring navigation id:",
          //   event.restoredState.navigationId
          // );
        }
        // if (this.router.url == "/shopDetailForm" && event.url == "/") {
        //   this.router.navigateByUrl("/shopDetailForm");
        //   this.router.navigate(["/shopDetailForm"]);
        // } else if (
        //   this.router.url == "/shopDetailForm" &&
        //   event.url == "/homePage/shopItemList"
        // ) {
        //   this.router.navigateByUrl("/shopDetailForm");
        //   this.router.navigate(["/shopDetailForm"]);
        // }
        console.groupEnd();
      });
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    // this.onGooglePayLoaded();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
