import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { SwPush } from "@angular/service-worker";
@Injectable()
export class MessagingService {
  tokenID = new BehaviorSubject(null);
  deviceToken = this.tokenID.asObservable();

  public orderChange = new BehaviorSubject(null);
  orderChangeValue = this.orderChange.asObservable();

  constructor(
    public angularFireMessaging: AngularFireMessaging,
    public router: Router,
    readonly swPush: SwPush
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }
  async requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log(token);
        this.tokenID.next(token);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      // console.log("new message received. ", payload);
      // this.showCustomNotification(payload);

      if (payload) {
        navigator.serviceWorker
          .getRegistration("/firebase-cloud-messaging-push-scope")
          .then((reg) => {
            var options = {
              badge: payload.notification.icon,
              renotify: false,
              requireInteraction: true,
              silent: false,
              timestamp: Math.floor(Date.now()),
              body: payload.notification.body,
              icon: payload.notification.icon,
              // image: payload.notification.image,
              vibrate: [200, 100, 200, 100, 200],
              data: {
                url:
                  "https://www.dhinasiangadi.com/CustomerDemo/#/homePage/orderHistory",
              },
              actions: [
                {
                  action: "Customer_openOrder",
                  title: "Open",
                },
                {
                  action: "close",
                  title: "Close",
                },
              ],
            };

            let audio = new Audio();
            audio.src = "./assets/sounds/sisfus.mp3";
            audio.load();
            audio.play();
            // this.notify = new Notification("New Order!", options);
            reg.showNotification(payload.notification.title, options);
            reg.addEventListener("notificationclick", (event) => {
              // console.log(event);
            });
            // if(payload.notification.title === "Order Cancelled"){
            //   this.changeOrderStatus("Order Cancelled");
            // }else{
            //   this.changeOrderStatus(payload.notification.title);
            // }
            // this.router.navigate(["/homeScreen/orderListPage"]);
            // this.ngZone.run(() => this.router.navigate(["/homeScreen/orderListPage"]).then());
          });
      }

      // navigator.serviceWorker
      //   .getRegistration("/firebase-cloud-messaging-push-scope")
      //   .then((registration) => {
      //     registration.showNotification(notify_data["title"], options);
      //   });

      // notify.onclick = (event) => {
      //   event.preventDefault();
      //   // write the onlick function here
      // };

      // navigator.serviceWorker.getRegistration().then(reg => {
      //   reg.pushManager.subscribe({
      //     userVisibleOnly: true
      //   }).then(sub => {

      //   })
      // })
    });
  }

  changeOrderStatus(value) {
    // value = localStorage.getItem("orderCount");
    this.orderChange.next(value);
  }
}
