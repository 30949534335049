import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from "@angular/platform-browser";
import { NgModule, Injectable } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { HomePageComponent } from "./home-page/home-page.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomePageService } from "./service/home-page.service";
import { HttpClientModule } from "@angular/common/http";
import { LoginSignupFormComponent } from "./login-signup-form/login-signup-form.component";
import { LoginSignupService } from "./service/login-signup-service";
import { HomePageModule } from "./home-page/home-page.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./service/messaging.service";
import {
  AsyncPipe,
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
} from "@angular/common";
import { PaymentPageService } from "./service/payment-options.service";
import { RoutingGuard } from "./shared/routing.gaurd";
import { ErrorPagesComponent } from "./error-pages/error-pages.component";
import { AuthGaurd } from "./shared/auth.gaurd";
import { UserProfileService } from "./service/user-profile.service";
import { HomePageRoutingModule } from "./home-page/home-page-routing.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { UserProfileModule } from './home-page/user-profile/user-profile.module';

@Injectable()
export class BaluHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6,
    },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoginSignupFormComponent,
    ErrorPagesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomePageRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    HomePageModule,
    UserProfileModule,
    BrowserAnimationsModule,
    ScrollingModule,
    AngularFireMessagingModule,
    HammerModule,
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    AuthGaurd,
    RoutingGuard,
    HomePageService,
    LoginSignupService,
    PaymentPageService,
    UserProfileService,
    MessagingService,
    AsyncPipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: BaluHammerConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
