<mat-progress-bar *ngIf='isProfileChangesDone' mode="buffer"></mat-progress-bar>
<mat-toolbar class="bg-white sticky-top shadow-sm">
    <mat-toolbar-row>
        <!-- <span class="text-font-style">Profile</span> -->
        <span class="text-font-style d-block d-sm-block d-md-none d-lg-none d-xl-none" style="font-size: xx-large;">Profile</span>
        <span class="text-font-style display-4 d-none d-md-block d-lg-block d-xl-block">Profile</span>
        <span style="flex: 1 1 auto;"></span>
        <button class="btn btn-outline-light" mat-icon-button routerLink="/homePage/userProfile/">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
<mat-card class="container mt-5 shadow col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 mb-5">
    <form [formGroup]="userProfile" class="text-center justify-content-center" autocomplete="off">
        <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
        <mat-form-field class="w-75">
            <mat-label class="text-font-style" style="font-size: 14px;">Name</mat-label>
            <input type="text" class="text-font-style" matInput formControlName="name" placeholder="Ex. Sanketh" />
            <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your name</mat-hint>
            <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="userProfile.controls['name'].invalid">
                Please enter your name
            </mat-error>
            <!-- <mat-error
                *ngIf="userProfile.controls['name'].invalid && (userProfile.controls['name'].dirty || userProfile.controls['name'].touched)">
                <mat-error *ngIf="userProfile.controls['name'].errors.required">
                    Please enter your name
                </mat-error>
            </mat-error> -->
        </mat-form-field>
        <mat-form-field class="w-75">
            <mat-label class="text-font-style" style="font-size: 14px;">Email ID</mat-label>
            <input type="email" class="text-font-style" matInput formControlName="email" placeholder="Ex. abc@gmail.com (Optional)" />
            <mat-hint class="text-font-style" sytle="margin-top: 1px">Hey, What's your mail id?
            </mat-hint>
            <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="userProfile.controls['email'].invalid">
                Please enter valid email address
            </mat-error>
        </mat-form-field>
        <br>
        <div class="container mt-2 text-center justify-content-center" style="display: grid;">
            <mat-label class="text-font-style" style="font-size: 14px;">Profile photo</mat-label>
            <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
            <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file">

        </div>

        <div class="text-right">
            <button *ngIf="imageChangedEvent != ''" (click)="imageChangedEvent = ''" mat-icon-button>
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <div class="d-flex" style="margin-top: 5%;">
            <div class="col-12">
                <image-cropper *ngIf="imageChangedEvent != ''" [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToHeight]="165" [cropperMinWidth]="200"
                    [cropperMinHeight]="200" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
            </div>
            <!-- <div *ngIf="imageChangedEvent != ''" class="pl-0 col-6">
                <img [src]="croppedImage" style="height: 165px;" />
            </div> -->
        </div>
        <button class="col-4 mt-3 btn btn-outline-warning" mat-raised-button color="accent"
            (click)="userProfile.valid ? saveProfileChanges() : null">Save</button>
    </form>
</mat-card>