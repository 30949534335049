import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AddressManagerComponent } from "./address-manager/address-manager.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { HelpComponent } from "./help/help.component";
import { AboutComponent } from "./about/about.component";
import { ProfileEditComponent } from "./profile-edit/profile-edit.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material/material.module";
import { HttpClientModule } from "@angular/common/http";
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    AddressManagerComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    HelpComponent,
    AboutComponent,
    ProfileEditComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    UserProfileRoutingModule,
    ScrollingModule
  ],
  exports: [
    AboutComponent
  ]
})
export class UserProfileModule {}
