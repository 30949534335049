<body class="style-6">
    <div class="container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 col-12 mt-2 mb-2">
            <!-- <ng-container matColumnDef="item_id">
                <th class="text-left" mat-header-cell *matHeaderCellDef> No </th>
                <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                <td mat-footer-cell *matFooterCellDef><strong>Total</strong></td>
            </ng-container> -->
            <ng-container matColumnDef="item_name">
                <th class="text-font-style text-left" mat-header-cell *matHeaderCellDef> Item </th>
                <td class="text-font-style w-50" mat-cell *matCellDef="let element">

                    <strong>
                        {{element.item_name}}({{element.quantityOption}})<br>
                        {{element.item_kname}}({{element.quantityOption}})
                    </strong>

                </td>
                <td class="text-font-style" mat-footer-cell *matFooterCellDef><strong>Total</strong></td>
            </ng-container>
            <ng-container matColumnDef="cost_price">
                <th class="text-font-style text-right" mat-header-cell *matHeaderCellDef>Quantity</th>
                <td class="text-font-style text-right" mat-cell *matCellDef="let element">

                    <!-- {{element.initialCost | currency:'INR'}} x {{element.initialQuantity}} -->
                    {{element.initialQuantity}}

                </td>
                <td class="text-font-style" mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="itemPrice">
                <th class="text-font-style text-right" mat-header-cell *matHeaderCellDef> Amount </th>
                <td class="text-font-style text-right" mat-cell *matCellDef="let element">
                    {{element.itemPrice | currency:'INR'}}
                </td>
                <td class="text-font-style text-right" mat-footer-cell *matFooterCellDef>
                    <strong>{{getTotalCost() | currency:'INR'}}</strong>
                </td>
            </ng-container>
            <tr class="text-font-style" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="text-font-style" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="text-font-style" mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            <!-- <tr mat-row *matFooterRowDef="displayedColumns">
                <td mat-cell>1</td>
                <td mat-cell>2</td>
                <td mat-cell>3</td>
                <td mat-cell>4</td>
            </tr> -->
        </table>

        <button *ngIf="!isPaymentPage" mat-raised-button color="primary"
            class="text-font-style mb-2 btn btn-outline-light float-right" (click)="proceedToPayment()">PROCEED</button>
    </div>

    <!-- Dialog box to go to payment options page -->
    <ng-template #proceedToPaymentDialog>
        <h3 matDialogTitle class="mb-2 text-font-style">Proceed to payment page?</h3>
        <mat-dialog-actions align="end">
            <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
            <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
        </mat-dialog-actions>
    </ng-template>

</body>