import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGaurd } from 'src/app/shared/auth.gaurd';
import { RoutingGuard } from 'src/app/shared/routing.gaurd';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AddressManagerComponent } from './address-manager/address-manager.component';
import { HelpComponent } from './help/help.component';
import { AboutComponent } from './about/about.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { HomePageComponent } from '../home-page.component';

const routes: Routes = [
  {
    path: "",
    component: HomePageComponent,
    canActivate: [AuthGaurd, RoutingGuard],
    children: [
      {
        path: "homePage/userProfile/profileEdit",
        component: ProfileEditComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile/changePassword",
        component: ChangePasswordComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile/addressManager",
        component: AddressManagerComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile/help",
        component: HelpComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile/about",
        component: AboutComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile/privacyPolicy",
        component: PrivacyPolicyComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
      {
        path: "homePage/userProfile/termsofuse",
        component: TermsOfUseComponent,
        canActivate: [AuthGaurd, RoutingGuard],
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class UserProfileRoutingModule { }
