<!-- <mat-toolbar color="primary">
    <mat-toolbar-row class="justify-content-between">
        <span class="text-font-style">Dhinasi Angadi</span>
        <button mat-icon-button (click)="logout()" class="btn text-white" style="outline: 0; background: #673ab7;">
            <mat-icon>power_settings_new</mat-icon>
          </button>
    </mat-toolbar-row>
</mat-toolbar> -->
<mat-progress-bar *ngIf="isLogout" mode="indeterminate"></mat-progress-bar>
<mat-progress-bar *ngIf="!isShopListLoaded" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid p-0 h-100">
    <div class="d-flex h-100  left-area-bg-image">
        <div class="col-6 p-0 d-none d-md-block d-lg-block d-xl-block">
            <div class="row">
                <div class="d-inline p-3 text-right position-relative" style="bottom:20%">
                    <div class="text-center">
                        <img src="../../assets/icons/Dhinasi_Angadi_512x512.jpg" class="rounded-circle img-fluid shadow"
                            alt="Responsive image" height="150" width="150">
                    </div>
                    <p class="text-font-style text-dark display-4 mt-2 w-75 float-right mr-3" style="font-size: 2rem;">LOOKING FOR LOCAL GROCERY SHOPS NEAR YOU?</p>
                    <p class="text-font-style text-dark text-right float-right mr-3">One stop place for all your grocery needs</p>
                </div>
            </div>
        </div>
        <!-- <div class="col-6 p-0 bg-dark d-none d-md-block d-lg-block d-xl-block">
            <img src="../../assets/icons/Dhinasi_Angadi_512x512.jpg" class="img-fluid justify-content-center position-absolute" alt="Responsive image" height="50" width="50">
            <p class="display-4 text-font-style position-absolute mt-2">LOOKING FOR LOCAL GROCERY SHOPS NEAR YOU?</p>
            <div class="row justify-content-center align-items-center h-100">
                <div class="d-inline">
                    <img src="../../assets/gif/delivery_gif.gif" class="img-fluid" alt="Responsive image">
                </div>
            </div>
        </div> -->
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 shop-details-scrollable">
            <form [formGroup]="groceryDetails" class="text-center">
                <div class="text-center mt-2">
                    <mat-form-field class="searchArea-width">
                        <mat-label class="text-font-style">Select Shop Area</mat-label>
                        <mat-select class="text-font-style" formControlName="shopArea"
                            (selectionChange)="onAreaChange();">
                            <mat-option class="text-font-style" value="None">None</mat-option>
                            <mat-option class="text-font-style" [value]="data"
                                *ngFor="let data of areaList; index as i">
                                {{data}}
                            </mat-option>
                        </mat-select>
                        <mat-hint class="text-font-style" sytle="margin-top: 1px">Select the grocery shop area
                        </mat-hint>
                        <mat-error class="text-font-style" style="margin-top: 1px"
                            *ngIf="groceryDetails.controls['shopArea'].invalid">Please select area</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="(shopList.length == 0) || groceryDetails.controls['shopArea'].value == 'None'" class="row justify-content-center align-items-center">
                    <div class="d-inline">
                        <img class="jump" src="assets/images/arrow-up.png" height="60" width="35">
                        <p class="text-font-style">Shop list will appear when you choose the area.</p>
                        <p class="text-font-style">ದಯವಿಟ್ಟು ಅಂಗಡಿಯ ಸ್ಥಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ</p>
                    </div>
                </div>

                <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none h-100 w-100">
                    <div class="container-fluid text-center h-100">
                        <div class="row justify-content-center mt-1">
                            <mat-card (click)="requestShopDetails(shoplist)"
                                *ngFor="let shoplist of shopList; index as i" class="m-2 p-0 user-profile-card shadow">
                                <mat-card-header class="justify-content-center">
                                    <img mat-card-image class="coverImage" [src]="shoplist.shopImage">
                                </mat-card-header>
                                <div class="userProfileImage">
                                    <img mat-card-image class="profileImage rounded-circle shadow"
                                        [src]="shoplist.shopkeeperImage">
                                </div>

                                <mat-card-content style="position: relative;">
                                    <!-- <div class="d-flex justify-content-between">
                                <span></span>
                                <p class="text-font-style m-0 shopName-font text-font-style"></p>
                            </div> -->
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <p class="text-font-style m-0 shopName-font">{{shoplist.shopname_kannada}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <p class="text-font-style m-0 shopName-font">{{shoplist.shopname}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <p style="border-top-left-radius: 15px; border-bottom-left-radius: 15px;"
                                            class="heart text-font-style m-0 shopName-font"
                                            [ngClass]="shoplist.online_status == 'Offline'? 'bg-danger': 'bg-success'">
                                            {{shoplist.online_status == 'Offline'? 'Currently Unavailable':
                                            'Available'}}</p>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <!-- md, lg, xl -->
                <div class="d-none d-md-block d-lg-block d-xl-block h-100">
                    <div class="container-fluid text-center h-100">
                        <div class="row justify-content-center mt-1">
                            <mat-card (click)="requestShopDetails(shoplist)"
                                *ngFor="let shoplist of shopList; index as i" class="p-0 m-2 user-profile-card shadow">
                                <mat-card-header class="justify-content-center">
                                    <img mat-card-image class="coverImage" [src]="shoplist.shopImage">
                                </mat-card-header>
                                <div class="userProfileImage">
                                    <img mat-card-image class="profileImage rounded-circle shadow"
                                        [src]="shoplist.shopkeeperImage">
                                </div>

                                <mat-card-content style="position: relative;">
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <p class="text-font-style m-0 shopName-font text-font-style">
                                            ({{shoplist.shopname_kannada}})</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <p class="text-font-style m-0 shopName-font text-font-style">
                                            {{shoplist.shopname}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span></span>
                                        <p style="border-top-left-radius: 15px; border-bottom-left-radius: 15px;"
                                            class="heart text-font-style m-0 shopName-font"
                                            [ngClass]="shoplist.online_status == 'Offline'? 'bg-danger': 'bg-success'">
                                            {{shoplist.online_status == 'Offline'? 'Currently Unavailable':
                                            'Available'}}</p>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ng-template #logoutDialog>
        <p matDialogTitle class="mb-2 text-font-style"><small>Are you sure you want to logout?</small></p>
        <mat-dialog-actions align="end">
            <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
            <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
        </mat-dialog-actions>
    </ng-template>

    <ng-template #generalDialog>
        <p class="text-font-style" mat-dialog-title>{{dialogData.title}}</p>
        <mat-dialog-content>
            <img class="mt-0 rounded-lg" mat-card-image [src]="dialogData.imagePath" draggable="false">
            <p class="text-font-style" style="white-space: break-spaces;" [innerHTML]="dialogData.content"></p>
            <p class="text-font-style text-danger"><strong>{{dialogData.note}}</strong></p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button class="btn btn-outline-light text-font-style" mat-stroked-button
                [matDialogClose]="dialogData.no">{{dialogData.no}}</button>
            <button class="btn btn-outline-light text-font-style" mat-stroked-button
                [matDialogClose]="dialogData.yes">{{dialogData.yes}}</button>
        </mat-dialog-actions>
    </ng-template>
</div>