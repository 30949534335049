import { Component, OnInit } from "@angular/core";
import { LoginSignupService } from "../service/login-signup-service";
import { HomePageService } from "../service/home-page.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-error-pages",
  templateUrl: "./error-pages.component.html",
  styleUrls: ["./error-pages.component.css"],
})
export class ErrorPagesComponent implements OnInit {
  statusCode: any;
  constructor(
    public loginService: LoginSignupService,
    public homePageService: HomePageService,
    public router: Router,
    public _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    let LoginPageErrorCode = localStorage.getItem("LoginPageErrorCode");
    let HomePageErrorCode = localStorage.getItem("HomePageErrorCode");
    if (LoginPageErrorCode != null) {
      this.statusCode = LoginPageErrorCode;
    } else if (HomePageErrorCode != null) {
      this.statusCode = HomePageErrorCode;
    } else {
      this.statusCode = "Not Online";
    }
  }
  checkConnection() {
    if (navigator.onLine) {
      this._snackBar.open(
        "No Internet!. Please check your internet connection",
        null,
        {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        }
      );
    }
  }
}
