<div class="container mt-3">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let element of questionsAndAnswers; index as i">
            <mat-expansion-panel-header class='p-3' [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                <mat-panel-title class="text-font-style">
                    <strong>Q{{i+1}}. {{element.question}}</strong>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container d-flex">
                <mat-icon style="font-size: larger;">keyboard_arrow_right</mat-icon><p class="ml-1 text-font-style" [innerHTML]="element.answer"></p>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>