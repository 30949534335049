import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthGaurd implements CanActivate {
  isValidUser: boolean;
  constructor(public router: Router) {
    this.isValidUser = false;
  }

  canActivate(): boolean {	
    if (localStorage.getItem("Customer_sessionData") == null) {	
      this.isValidUser = false;	
      this.router.navigate(["/login"]);	
    } else {	
      this.isValidUser = true;	
    }	
    return this.isValidUser;	
  }
}