import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginSignupFormComponent } from './login-signup-form/login-signup-form.component';
import { RoutingGuard } from './shared/routing.gaurd';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { AuthGaurd } from './shared/auth.gaurd';
import { ShopDetailsFormComponent } from './home-page/shop-details-form/shop-details-form.component';


const routes: Routes = [
  {
    // path: "",
    // component: LoginSignupFormComponent
    path: "",
    redirectTo: "homePage/shopDetailForm",
    pathMatch: 'full'
  },
  {
    path: "login",
    component: LoginSignupFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
