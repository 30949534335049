<mat-toolbar class="bg-white sticky-top shadow-sm">
    <mat-toolbar-row >
        <!-- <span class="text-font-style">Change password</span> -->
        <span class="text-font-style d-block d-sm-block d-md-none d-lg-none d-xl-none" style="font-size: xx-large;">Change Password</span>
        <span class="text-font-style display-4 d-none d-md-block d-lg-block d-xl-block">Change Password</span>
        <span style="flex: 1 1 auto;"></span>
        <button class="btn btn-outline-light" mat-icon-button routerLink="/homePage/userProfile/">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
<mat-card class="container mt-5 shadow col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 mb-5">

    <form [formGroup]="changePassword" class="text-center justify-content-center" autocomplete="off">
        <mat-form-field class="w-75">
            <mat-label class="text-font-style" style="font-size: 14px;">Old Password</mat-label>
            <input matInput formControlName="oldPassword" [type]="hide1 ? 'password' : 'text'" />
            <mat-hint class="text-font-style" sytle="margin-top: 1px">Your recent password</mat-hint>
            <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? "visibility_off" : "visibility"}}</mat-icon>
            </button>
            <mat-error class="text-font-style" style="margin-top: 1px" *ngIf="changePassword.controls['oldPassword'].invalid">
                Old Password is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-75">
            <mat-label class="text-font-style" style="font-size: 14px;">New Password</mat-label>
            <input matInput formControlName="newPassword" [type]="hide2 ? 'password' : 'text'" />
            <mat-hint class="text-font-style" sytle="margin-top: 1px">Give a strong password</mat-hint>
            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide2">
                <mat-icon>{{hide2 ? "visibility_off" : "visibility"}}</mat-icon>
            </button>
            <mat-error
                *ngIf="changePassword.controls['newPassword'].invalid && (changePassword.controls['newPassword'].dirty || changePassword.controls['newPassword'].touched)">
                <mat-error *ngIf="changePassword.controls['newPassword'].errors.required">
                    Password is required.
                </mat-error>
                <mat-error *ngIf="changePassword.controls['newPassword'].errors?.pattern">
                    Password should have min 8 characters. (A-Z, a-z, 0-9 & one special character).
                </mat-error>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-75">
            <mat-label class="text-font-style" style="font-size: 14px;">Confirm password
            </mat-label>
            <input matInput formControlName="confirmNewPassword" [type]="hide3 ? 'password' : 'text'" />
            <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide3">
                <mat-icon>{{hide3 ? "visibility_off" : "visibility"}}</mat-icon>
            </button>
            <mat-error
                *ngIf="changePassword.controls['confirmNewPassword'].invalid && (changePassword.controls['confirmNewPassword'].dirty || changePassword.controls['confirmNewPassword'].touched)">
                <mat-error *ngIf="changePassword.controls['confirmNewPassword'].errors.required">
                    Confirm password is required.
                </mat-error>
                <mat-error *ngIf="changePassword.controls['confirmNewPassword'].errors.mustMatch">
                    Confirm password does not match.
                </mat-error>
            </mat-error>
        </mat-form-field>
        <button class="col-4 mt-3 btn btn-outline-warning" (click)="changePassword.valid ? changePasswordBtn() : null"
            mat-raised-button color="accent">Submit</button>
    </form>
</mat-card>