import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LoginSignupService } from "../service/login-signup-service";
import { MessagingService } from "../service/messaging.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit {
  @ViewChild("logoutDialog", { static: true })
  logoutDialog: TemplateRef<any>;
  @ViewChild("goBackDialog", { static: true })
  goBackDialog: TemplateRef<any>;
  isLogout: boolean;
  isProfileImageEmpty: any;
  userName: string;
  email: string;
  mobileNumber: string;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public service: LoginSignupService,
    public messagingService: MessagingService
  ) {}
  ngOnInit(): void {
    this.isLogout = false;
    this.isProfileImageEmpty =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))[
            "customer_profileimage"
          ];
    this.userName =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))[
            "customer_name"
          ];
    this.email =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))["email"];
    this.mobileNumber =
      localStorage.getItem("Customer_sessionData") === null
        ? ""
        : JSON.parse(localStorage.getItem("Customer_sessionData"))["username"];
    // Route it to shop form details page
    // this.router.navigate(["/homePage/shopDetailForm"]);
    // this.router.navigate(["/homePage"]);
  }
  logout() {
    const dialogRef = this.dialog.open(this.logoutDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.isLogout = true;
          let data = JSON.parse(localStorage.getItem("Customer_sessionData"));
          this.messagingService.deviceToken.subscribe((token) => {
            data.deviceToken = (token === null ? data.username + "_noDeviceIdGenerated" : token);
          });
          this.service.logout(data).subscribe((result) => {
            if (result == "Logged out successfully") {
              this.isLogout = false;
              // localStorage.clear();
              localStorage.removeItem("shopId");
              localStorage.removeItem("source");
              localStorage.removeItem("Customer_sessionData");
              localStorage.removeItem("HomePageErrorCode");
              localStorage.removeItem("LoginPageErrorCode");
              localStorage.removeItem("cartItemList");
              this.router.navigate(["/"]);
            } else {
              console.log("not loged out");
            }
          });
        }
      }
    });
  }

  goBack() {
    const dialogRef = this.dialog.open(this.goBackDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result == "Yes") {
          this.router.navigate(["/homePage/shopDetailForm"]);
        }
      }
    });
  }
}
