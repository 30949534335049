<mat-progress-bar *ngIf="isOrderHistoryReceived" mode="indeterminate"></mat-progress-bar>

<div *ngIf="!isOrderHistoryReceived" class="d-block d-sm-block d-md-none d-lg-none d-xl-none p-2 w-100 h-100">
    <div *ngIf="orderHistoryData.length == 0" class="row justify-content-center align-items-center h-100">
        <div class="w-100 text-center">
            <img src="./assets/images/shopping-cart.png" width="200" height="200">
            <div class="text-center">
                <p class="m-0 p-3 text-font-style">No orders in your history</p>
            </div>
            <div class="text-center mt-5">
                <button routerLink="/homePage/shopDetailForm" class="col-6 btn btn-outline-light text-font-style"
                    mat-raised-button>
                    Order Items <mat-icon>arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-accordion *ngFor="let orderDetail of itemList; index as i" class="m-0 p-0">
        <mat-expansion-panel
            [ngClass]="orderDetail.order_status == 'Cancelled' ? 'cancelled' : (orderDetail.order_status == 'Accepted' ? 'accepted' : (orderDetail.order_status == 'Ordered') ? 'ordered' : (orderDetail.order_status == 'Picked') ? 'picked' : 'delivered')"
            class="p-2 mb-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <!-- <mat-progress-bar *ngIf="orderDetail.order_status == 'Ordered'" mode="indeterminate"></mat-progress-bar> -->
            <mat-expansion-panel-header
                [ngClass]="orderDetail.order_status == 'Cancelled' ? 'cancelled' : (orderDetail.order_status == 'Accepted' ? 'accepted' : (orderDetail.order_status == 'Ordered') ? 'ordered' : (orderDetail.order_status == 'Picked') ? 'picked' : 'delivered')">
                <div class="d-inline">
                    <mat-panel-title>
                        <h2 class="text-font-style">Order ID: {{orderDetail.order_id}}</h2>
                    </mat-panel-title>
                    <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Cancelled'">thumb_down_alt
                    </mat-icon>
                    <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Accepted'">
                        done</mat-icon>
                    <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Delivered'">done_all
                    </mat-icon>
                    <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Picked'">moped
                    </mat-icon>
                    <mat-spinner [diameter]="25" class="statusIconPosition"
                        *ngIf="orderDetail.order_status == 'Ordered'"></mat-spinner>
                    <!-- <mat-panel-description class="text-font-style">Status: {{orderDetail.order_status}}
                    </mat-panel-description> -->
                </div>
            </mat-expansion-panel-header>
            <!-- <mat-card class="mt-1 mb-1 w-100">
                <mat-card-header class="justify-content-between">
                    <mat-card-title>Ordered item list</mat-card-title>
                </mat-card-header>
            </mat-card> -->
            <ng-template matExpansionPanelContent class="p-0">
                <div class="text-left ml-2" style="font-size: medium;">
                    <mat-panel-description class="text-font-style text-left text-dark">
                        Status: {{orderDetail.order_status}}
                    </mat-panel-description>
                    <mat-panel-description class="text-font-style text-left text-dark">
                        Payment:
                        {{orderDetail.paymenttype == 'NCOD' ? 'To Shopkeeper' : orderDetail.paymenttype == 'COD' ? 'Cash on delivery' : orderDetail.paymenttype}}
                    </mat-panel-description>
                    <mat-panel-description class="text-font-style text-left text-dark">
                        Shop: {{orderDetail.shopname}}
                    </mat-panel-description>
                    <mat-panel-description class="text-font-style text-left text-dark">
                        Time slot: {{orderDetail.delivertime}}
                    </mat-panel-description>
                    <mat-panel-description class="text-font-style text-left text-dark">
                        Delivery charge: {{orderDetail.deliveryamount | currency:'INR'}}
                    </mat-panel-description>
                    <mat-panel-description class="text-font-style text-left text-dark">
                        Ordered date: {{orderDetail.timestamp | date:'longDate'}}
                    </mat-panel-description>
                </div>
                <table mat-table [dataSource]="orderDetail.cart_Items"
                    class="mat-elevation-z8 col-12 mt-2 mb-2 text-left">
                    <!-- <ng-container matColumnDef="item_id">
                            <th class="text-left" mat-header-cell *matHeaderCellDef> No </th>
                            <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container> -->
                    <ng-container matColumnDef="item_name">
                        <th class="text-left text-font-style" mat-header-cell *matHeaderCellDef> Item
                        </th>
                        <td class="text-font-style w-50" mat-cell *matCellDef="let element">


                            <strong>
                                {{element.item_name}}({{element.quantityOption}})<br>
                                {{element.item_kname}}({{element.quantityOption}})
                            </strong>


                        </td>
                        <td class="text-font-style" mat-footer-cell *matFooterCellDef>
                            <strong>Total</strong></td>
                    </ng-container>
                    <ng-container matColumnDef="cost_price">
                        <th class="text-font-style text-right" mat-header-cell *matHeaderCellDef>
                            Quantity</th>
                        <td class="text-font-style text-right" mat-cell *matCellDef="let element">

                            <!-- {{element.initialCost | currency:'INR'}} x {{element.initialQuantity}} -->
                            {{element.initialQuantity}}

                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="itemPrice">
                        <th class="text-font-style text-right" mat-header-cell *matHeaderCellDef> Amount
                        </th>
                        <td class="text-font-style text-right" mat-cell *matCellDef="let element">
                            {{element.itemPrice | currency:'INR'}}</td>
                        <td class="text-font-style text-right" mat-footer-cell *matFooterCellDef>
                            <strong>{{getTotalCost(orderDetail, i) | currency:'INR'}}</strong>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
            </ng-template>
            <mat-action-row *ngIf="orderDetail.order_status != 'Delivered'" class="p-0">
                <h2 class="text-font-style text-dark font-weight-bold m-0">Amount to pay: 
                    {{orderDetail.finalAmountToPay | currency:'INR'}}</h2>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div *ngIf="!isOrderHistoryReceived" class="d-none d-md-block d-lg-block d-xl-block w-100 h-100">
    <div *ngIf="orderHistoryData.length == 0" class="row justify-content-center align-items-center h-100">
        <div class="w-100 text-center">
            <img src="./assets/images/shopping-cart.png" width="200" height="200">
            <div class="text-center">
                <p class="m-0 p-3 text-font-style">No orders in your list.</p>
            </div>
            <div class="text-center mt-5">
                <button routerLink="/homePage/shopDetailForm" class="col-3 btn btn-outline-light text-font-style"
                    mat-raised-button>
                    Order Items <mat-icon>arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="container p-5">
        <mat-accordion *ngFor="let orderDetail of itemList; index as i">
            <mat-expansion-panel
                [ngClass]="orderDetail.order_status == 'Cancelled' ? 'cancelled' : (orderDetail.order_status == 'Accepted' ? 'accepted' : (orderDetail.order_status == 'Ordered') ? 'ordered' : (orderDetail.order_status == 'Picked') ? 'picked' : 'delivered')"
                class="p-2 mb-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header
                    [ngClass]="orderDetail.order_status == 'Cancelled' ? 'cancelled' : (orderDetail.order_status == 'Accepted' ? 'accepted' : (orderDetail.order_status == 'Ordered') ? 'ordered' : (orderDetail.order_status == 'Picked') ? 'picked' : 'delivered')">
                    <div class="d-inline w-100">
                        <mat-panel-title>
                            <h2 class="text-font-style">Order ID : {{orderDetail.order_id}}</h2>
                        </mat-panel-title>
                        <mat-spinner [diameter]="25" class="statusIconPosition"
                            *ngIf="orderDetail.order_status == 'Ordered'"></mat-spinner>
                        <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Cancelled'">
                            thumb_down_alt
                        </mat-icon>
                        <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Accepted'">
                            done</mat-icon>
                        <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Delivered'">done_all
                        </mat-icon>
                        <mat-icon class="statusIconPosition" *ngIf="orderDetail.order_status == 'Picked'">moped
                        </mat-icon>
                        <!-- <mat-panel-description class="text-font-style">Status: {{orderDetail.order_status}}
                    </mat-panel-description> -->

                    </div>
                </mat-expansion-panel-header>
                <!-- <mat-card class="mt-1 mb-1 w-100">
                    <mat-card-header class="justify-content-between">
                        <mat-card-title>Ordered item list</mat-card-title>
                    </mat-card-header>
                </mat-card> -->
                <ng-template matExpansionPanelContent class="p-0">
                    <div class="text-left ml-2" style="font-size: medium;">
                        <mat-panel-description class="text-font-style text-left text-dark">
                            Status: {{orderDetail.order_status}}
                        </mat-panel-description>
                        <mat-panel-description class="text-font-style text-left text-dark">
                            Payment:
                            {{orderDetail.paymenttype == 'NCOD' ? 'To Shopkeeper' : orderDetail.paymenttype == 'COD' ? 'Cash on delivery' : orderDetail.paymenttype}}
                        </mat-panel-description>
                        <mat-panel-description class="text-font-style text-left text-dark">
                            Shop: {{orderDetail.shopname}}
                        </mat-panel-description>
                        <mat-panel-description class="text-font-style text-left text-dark">
                            Time slot: {{orderDetail.delivertime}}
                        </mat-panel-description>
                        <mat-panel-description class="text-font-style text-left text-dark">
                            Delivery charge: {{orderDetail.deliveryamount | currency:'INR'}}
                        </mat-panel-description>
                        <mat-panel-description class="text-font-style text-left text-dark">
                            Ordered date: {{orderDetail.timestamp | date:'longDate'}}
                        </mat-panel-description>
                    </div>
                    <table mat-table [dataSource]="orderDetail.cart_Items"
                        class="mat-elevation-z8 col-12 mt-2 mb-2 text-left">
                        <!-- <ng-container matColumnDef="item_id">
                                <th class="text-left" mat-header-cell *matHeaderCellDef> No </th>
                                <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container> -->
                        <ng-container matColumnDef="item_name">
                            <th class="text-left text-font-style" mat-header-cell *matHeaderCellDef> Item
                            </th>
                            <td class="text-font-style w-50" mat-cell *matCellDef="let element">
                                <strong>
                                    {{element.item_name}}({{element.quantityOption}})<br>
                                    {{element.item_kname}}({{element.quantityOption}})
                                </strong>
                            </td>
                            <td class="text-font-style" mat-footer-cell *matFooterCellDef>
                                <strong>Total</strong></td>
                        </ng-container>
                        <ng-container matColumnDef="cost_price">
                            <th class="text-font-style text-right" mat-header-cell *matHeaderCellDef>
                                Quantity</th>
                            <td class="text-font-style text-right" mat-cell *matCellDef="let element">

                                <!-- {{element.initialCost | currency:'INR'}} x {{element.initialQuantity}} -->
                                {{element.initialQuantity}}

                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="itemPrice">
                            <th class="text-font-style text-right" mat-header-cell *matHeaderCellDef> Amount
                            </th>
                            <td class="text-font-style text-right" mat-cell *matCellDef="let element">
                                {{element.itemPrice | currency:'INR'}}</td>
                            <td class="text-font-style text-right" mat-footer-cell *matFooterCellDef>
                                <strong>{{getTotalCost(orderDetail, i) | currency:'INR'}}</strong>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    </table>
                </ng-template>
                <mat-action-row *ngIf="orderDetail.order_status != 'Delivered'" class="p-0">
                    <h2 class="text-font-style text-dark font-weight-bold m-0">Amount to pay: 
                        {{orderDetail.finalAmountToPay | currency:'INR'}}</h2>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>



<ng-template #generalDialog>
    <p mat-dialog-title>{{dialogData.title}}</p>
    <mat-dialog-content>
        <p class="text-font-style">{{dialogData.content}}</p>
        <p class="text-font-style text-danger"><strong>{{dialogData.note}}</strong></p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
    </mat-dialog-actions>
</ng-template>