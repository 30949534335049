<mat-progress-bar *ngIf="itemsLoaded" mode="indeterminate"></mat-progress-bar>

<mat-toolbar *ngIf="!itemsLoaded" class="bg-light">
    <mat-toolbar-row class="pl-0 pr-0">
        <button [disableRipple]="true" routerLink="/homePage/shopDetailForm" class="btn btn-outline-light bg-light p-0 backBtnMargin" mat-button>
            <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-form-field class="ml-2 search-bar-width" color="primary">
            <mat-label class="text-font-style" style="font-size: small;">Search item</mat-label>
            <input #search matInput style="font-size: small;" placeholder="Ex. Rice, Sugar" class="w-75"
                (keyup)="onSearchKey(search.value)" autocomplete="off">
            <button class="btn btn-outline-light" mat-button *ngIf="search.value" matSuffix mat-icon-button
                aria-label="Clear" (click)="search.value =''; clearSearchValues();">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <span style="flex: 1 1 auto;"></span>
        <div>
            <button class="btn btn-outline-light bg-light mr-2" *ngIf="cartItemsCount > 0" (click)="clearCart();"
                mat-mini-fab>
                <mat-icon>clear</mat-icon>
            </button>
            <button *ngIf="!isSearching" color="accent" class="btn btn-outline-light mr-2" (click)="openBottomSheet();"
                mat-mini-fab>
                <mat-icon [matBadge]="cartItemsCount" matBadgePosition="after">shopping_cart</mat-icon>
            </button>
        </div>

    </mat-toolbar-row>
</mat-toolbar>
<marquee *ngIf="!itemsLoaded" class="text-font-style p-1 bg-light" behavior="scroll" direction="left" hspace="0" vspace="0" scrollamount="5" scrolldelay="50">Images shown below
    are just for customer reference. Grocery items quality may vary from shop to shop. --- ಕೆಳಗೆ
    ತೋರಿಸಿರುವ ಚಿತ್ರಗಳು ಗ್ರಾಹಕರ ಉಲ್ಲೇಖಕ್ಕಾಗಿ ಮಾತ್ರ. ದಿನಸಿ ವಸ್ತುಗಳ ಗುಣಮಟ್ಟ ಅಂಗಡಿಯಿಂದ ಅಂಗಡಿಗೆ ಬದಲಾಗಬಹುದು.
</marquee>
<!-- sm -->
<div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
    <div *ngIf="isSearchResultEmpty" class="row justify-content-center align-items-center h-75">
        <div class="w-100 text-center">
            <img src="./assets/images/search.png" width="200" height="200">
            <div class="text-center">
                <p class="m-0 p-3 text-font-style">No items found!
                </p>
            </div>
        </div>
    </div>
    <mat-tab-group *ngIf="!isSearchResultEmpty" animationDuration="1000ms" [selectedIndex]="selectedTab"
        (selectedTabChange)="changeSelectedTabIndex($event)">
        <mat-tab *ngFor="let data of itemList; index as i">
            <ng-template matTabLabel>
                <span class="text-font-style font-weight-bold">{{itemCategories[i]}}</span>
            </ng-template>
            <ng-template matTabContent>
                <div class="container-fluid p-0 text-center h-100 mb-5">
                    <!-- <mat-form-field class="w-50">
                    <mat-label>Message</mat-label>
                    <input matInput #mySearchInput (input)="filterItem(mySearchInput.value)">
                    <mat-hint align="start"><strong>Don't disclose personal info</strong> </mat-hint>
                </mat-form-field>
                <mat-divider></mat-divider> -->
                    <cdk-virtual-scroll-viewport itemSize="135" minBufferPx="200" maxBufferPx="400"
                        class="cdkScrolling style-6" (swipeleft)="swipe($event)" (swiperight)="swipe($event)">
                        <div *ngIf="!itemsLoaded" class="row justify-content-center mt-1 itemListScrollbar">
                            <div *cdkVirtualFor="let innerData of itemList[i]; index as i; templateCacheSize: 0;"
                                class="item-click-style m-2">
                                <mat-card class="matcard shadow-sm border pl-1 pr-1" [attr.title]="innerData.item_name">
                                    <mat-card-header class="matcardheader justify-content-between">
                                        <!-- <img mat-card-avatar class="matcardavatar" [src]="data.item_imgpath"> -->
                                        <mat-card-title class="matcardtitle text-font-style"
                                            style="font-size: x-small;">
                                            <small><strong>{{innerData.item_name}}</strong></small></mat-card-title>
                                        <span [(ngModel)]="innerData.itemPrice" ngDefaultControl><strong>
                                                {{innerData.itemPrice | currency:'INR'}}</strong></span>
                                    </mat-card-header>
                                    <mat-card-header style="position: relative; bottom: 11%;">
                                        <mat-card-title class="matcardtitlekannada text-font-style"
                                            style="font-size: x-small;">
                                            <small><strong>{{innerData.item_kname}}</strong></small></mat-card-title>
                                    </mat-card-header>

                                    <div class="text-center">
                                        <img mat-card-image [src]="innerData.item_imgpath" class='itemimage'
                                            draggable="false" (click)="showItemImage(innerData)">
                                    </div>
                                    <mat-card-content class="matcardcontent">
                                        <div
                                            class="text-center d-flex justify-content-between matcardcontentjustification">
                                            <mat-form-field class="matformfield">
                                                <mat-label class="text-font-style">Select QTY</mat-label>
                                                <mat-select [(ngModel)]="innerData.quantityOption"
                                                    (selectionChange)="changeAmout(innerData, innerData.quantityOptions.indexOf($event.value))">
                                                    <mat-option class="text-font-style"
                                                        *ngFor="let option of innerData.quantityOptions; index as i"
                                                        [value]="option">{{option}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <button *ngIf="!innerData.isItemAdded" mat-raised-button
                                                class="btn btn-outline-light matraisedbuttonadditem text-white text-font-style"
                                                color="primary" (click)="addItemsToCart(data[i])">
                                                <p class="addButtonText">ADD</p>
                                            </button>
                                            <div class="itemAddButtonOuter d-flex justify-content-center"
                                                *ngIf="innerData.isItemAdded">
                                                <button mat-mini-fab color="primary"
                                                    class="btn btn-outline-light matbuttonaddremove"
                                                    (click)="decreaseCount(data[i])">
                                                    <mat-icon class="maticon">remove</mat-icon>
                                                </button>
                                                <mat-chip class="btn btn-outline-light matchip">
                                                    {{innerData.initialQuantity}}
                                                </mat-chip>
                                                <button mat-mini-fab color="primary"
                                                    class="btn btn-outline-light matbuttonaddremove"
                                                    (click)="increaseCount(data[i])">
                                                    <mat-icon class="maticon">add</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<!-- md, lg, xl -->
<div class="d-none d-md-block d-lg-block d-xl-block">
    <div *ngIf="isSearchResultEmpty" class="row justify-content-center align-items-center h-75">
        <div class="w-100 text-center">
            <img src="./assets/images/search.png" width="200" height="200">
            <div class="text-center">
                <p class="m-0 p-3 text-font-style">No items found!
                </p>
            </div>
        </div>
    </div>
    <mat-tab-group *ngIf="!isSearchResultEmpty" animationDuration="1000ms" [selectedIndex]="selectedTab"
        (selectedTabChange)="changeSelectedTabIndex($event)">
        <mat-tab *ngFor="let data of itemList; index as i">
            <ng-template matTabLabel>
                <span class="text-font-style font-weight-bold">{{itemCategories[i]}}</span>
            </ng-template>
            <ng-template matTabContent>
                <div class="container-fluid p-0 text-center h-100 mb-5">
                    <!-- <mat-form-field class="w-50">
                    <mat-label>Message</mat-label>
                    <input matInput #mySearchInput (input)="filterItem(mySearchInput.value)">
                    <mat-hint align="start"><strong>Don't disclose personal info</strong> </mat-hint>
                </mat-form-field>
                <mat-divider></mat-divider> -->
                    <cdk-virtual-scroll-viewport itemSize="50" class="cdkScrolling style-shopitems"
                        (swipeleft)="swipe($event)" (swiperight)="swipe($event)">
                        <div *ngIf="!itemsLoaded" class="row justify-content-center mt-1 itemListScrollbar">
                            <div *cdkVirtualFor="let innerData of itemList[i]; index as i; templateCacheSize: 0;"
                                class="item-click-style m-2">
                                <mat-card class="matcard shadow-sm border pl-1 pr-1" [attr.title]="innerData.item_name">
                                    <mat-card-header class="matcardheader justify-content-between">
                                        <!-- <img mat-card-avatar class="matcardavatar" [src]="data.item_imgpath"> -->
                                        <mat-card-title class="matcardtitle text-font-style"
                                            style="font-size: x-small;">
                                            <small><strong>{{innerData.item_name}}</strong></small></mat-card-title>
                                        <span [(ngModel)]="innerData.itemPrice" ngDefaultControl><strong>
                                                {{innerData.itemPrice | currency:'INR'}}</strong></span>
                                    </mat-card-header>
                                    <mat-card-header style="position: relative; bottom: 10%;">
                                        <mat-card-title class="matcardtitlekannada text-font-style"
                                            style="font-size: x-small;">
                                            <small><strong>{{innerData.item_kname}}</strong></small></mat-card-title>
                                    </mat-card-header>

                                    <div class="text-center">
                                        <img mat-card-image [src]="innerData.item_imgpath" class='itemimage'
                                            draggable="false" (click)="showItemImage(innerData)">
                                    </div>
                                    <mat-card-content class="matcardcontent">
                                        <div
                                            class="text-center d-flex justify-content-between matcardcontentjustification">
                                            <mat-form-field class="matformfield">
                                                <mat-label class="text-font-style">Select QTY</mat-label>
                                                <mat-select [(ngModel)]="innerData.quantityOption"
                                                    (selectionChange)="changeAmout(innerData, innerData.quantityOptions.indexOf($event.value))">
                                                    <mat-option class="text-font-style"
                                                        *ngFor="let option of innerData.quantityOptions; index as i"
                                                        [value]="option">{{option}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <button *ngIf="!innerData.isItemAdded" mat-raised-button
                                                class="btn btn-outline-light matraisedbuttonadditem text-white text-font-style"
                                                color="primary" (click)="addItemsToCart(data[i])">
                                                <p class="addButtonText">ADD</p>
                                            </button>
                                            <div class="itemAddButtonOuter d-flex justify-content-center"
                                                *ngIf="innerData.isItemAdded">
                                                <button mat-mini-fab color="primary"
                                                    class="btn btn-outline-light matbuttonaddremove"
                                                    (click)="decreaseCount(data[i])">
                                                    <mat-icon class="maticon">remove</mat-icon>
                                                </button>
                                                <mat-chip class="btn btn-outline-light matchip">
                                                    {{innerData.initialQuantity}}
                                                </mat-chip>
                                                <button mat-mini-fab color="primary"
                                                    class="btn btn-outline-light matbuttonaddremove"
                                                    (click)="increaseCount(data[i])">
                                                    <mat-icon class="maticon">add</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<!-- Dialog box to clear cart items -->
<ng-template #clearCartDialog>
    <p matDialogTitle class="mb-2 text-font-style"><small>Clear all cart items?</small></p>
    <mat-dialog-actions align="end">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
    </mat-dialog-actions>
</ng-template>

<!-- Dialog box for go back to shop selection page -->
<ng-template #goBackDialog>
    <p matDialogTitle class="mb-2 text-font-style"><small>Go to shop selection page?</small></p>
    <mat-dialog-actions align="end">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #generalDialog>
    <p class="text-font-style" mat-dialog-title>{{dialogData.title}}</p>
    <mat-dialog-content>
        <img class="mt-0 rounded-lg" mat-card-image [src]="dialogData.imagePath" draggable="false">
        <!-- <p class="text-font-style">{{dialogData.content}}</p> -->
        <!-- <p class="text-font-style text-danger"><small><strong>{{dialogData.note}}</strong></small></p> -->
    </mat-dialog-content>
    <!-- <mat-dialog-actions align="end">
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="No">No</button>
        <button class="btn btn-outline-light text-font-style" mat-stroked-button matDialogClose="Yes">Yes</button>
    </mat-dialog-actions> -->
</ng-template>